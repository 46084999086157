import { DRIVER_LIST, GET_DRIVER, COUNTRY_LIST } from './driverTypes'
import jwt_decode from "jwt-decode";
import Api from '../../components/Api';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const driversList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var store: any = jwt_decode(token);
        Api.get(`/store/drivers/${store.store_id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: DRIVER_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addDriver = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var store: any = jwt_decode(token);
        data.store_id = store.store_id;
        data.user_info = store;
        Api.post('/drivers/add_driver', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/drivers";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_DUP_ENTRY") {
                            error = "Driver Already Exist"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editDriver = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var store: any = jwt_decode(token);
        data.user_info = store;
        Api.put(`/drivers/edit_driver/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/drivers";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getDriver = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/drivers/driver/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_DRIVER,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const delDriver = (id: number, email_address: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let data = {
            email_address: email_address,
            user_info: jwt_decode(token)
        }
        Api.post(`/drivers/del_driver/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/drivers";
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockuser = (id: any, status: any, email_address: any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (status == 0) {
            is_active = 1
        } else if (status == 1) {
            is_active = 0
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            status: is_active,
            email_address:email_address,
            user_info: jwt_decode(token)
        }
        Api.put(`/drivers/driver_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/drivers"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const handleClockIn = (id: any, clockIn: any, email_address: any) => {
    return function (dispatch: any) {
        let clockInOut: any;
        if (clockIn == 0) {
            clockInOut = 1
        } else if (clockIn == 1) {
            clockInOut = 0
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            clockIn: clockInOut,
            email_address:email_address,
            user_info: jwt_decode(token)
        }
        Api.put(`/drivers/driver_clockin/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/drivers"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const countryList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/countries', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log(response.data.successResponse)
                    dispatch({
                        type: COUNTRY_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}