import { RECENT_PAYMENTS, LINE_CHART, PIE_CHART_SOS, DOUGNUT_CHART_CHANNEL, RECENT_ORDERS, RECENT_ITEMS, HOME_COUNTER, MONTHLY_ORDERS, MONTHLY_SALES,CUSTOMERS_LIST } from '../actions/homeType'
const initialState: any = {
    counter: {},
    monthlyOrders: [] || "",
    monthlySales: [] || "",
    paymentData: [] || "",
    orderData: [] || "",
    itemsData: [] || "",
    customerData: [] || "",
    lineChart: {} || "",
    pieChartSos: {} || "",
    dougnutChartChannel: {} || ""
}

const homeReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case HOME_COUNTER: return {
            ...state,
            counter: action.payload
        }
        case MONTHLY_ORDERS: return {
            ...state,
            monthlyOrders: action.payload
        }
        case MONTHLY_SALES: return {
            ...state,
            monthlySales: action.payload
        }
        case LINE_CHART: return {
            ...state,
            lineChart: action.payload
        }
        case PIE_CHART_SOS: return {
            ...state,
            pieChartSos: action.payload
        }
        case DOUGNUT_CHART_CHANNEL: return {
            ...state,
            dougnutChartChannel: action.payload
        }
        case RECENT_ORDERS: return {
            ...state,
            orderData: action.payload
        }
        case RECENT_PAYMENTS: return {
            ...state,
            paymentData: action.payload
        }
        case RECENT_ITEMS: return {
            ...state,
            itemsData: action.payload
        }
        case CUSTOMERS_LIST: return{
            ...state,
            customerData: action.payload
        }
        default: return state;
    }
}
export default homeReducer;