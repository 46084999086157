import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { cancelOrdersReasons, logoutUser, luanchTimer, ordersList, orderStatusList, showorderItems, statesList, stopTimer, storesList, updatefutureOrder, updateOrder } from '../../redux'
import { OrderProps } from '../../interfaces/order';
import BeautyStars from 'beauty-stars';
import Invoice from './invoice';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { filterOrdersList, unresolvedordersList, UnsuspendStore, updateunresolvedOrder } from '../../redux/actions/orderAction';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { currency } from '../../client-config';
import { LOGIN_STORE } from '../../redux/actions/orderType';
var QRCode = require('qrcode.react');
toast.configure();

class DriverEditor extends React.Component<{ data: any, row: any }, { [x: number]: any, deliveryboy: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            deliveryboy: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        const { row } = this.props
        this.setState({ deliveryboy: row.delivery_boy })
    }
    handleSave = () => {
        const { row } = this.props
        const { deliveryboy } = this.state;
        const data = {
            delivery_boy: deliveryboy ? deliveryboy : "",
        }
        if (deliveryboy !== "" && deliveryboy > 0) {
            this.props.data.updateunresolvedOrder(row.order_id, data, this.props.data.history)
        } else {
            this.setState({ deliveryboy: row.delivery_boy })
            toast.error("Please select the driver", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
        }
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        const { data, row } = this.props
        const { deliveryboy } = this.state;
        return (
            <div>
                {(row.order_status_code === 4 || row.order_status_code === 5 || row.order_status_code === 6) ? <p>{row.user_name}</p> :
                    (row.order_status_code !== 1 && row.order_status_code !== 2 && row.order_status_code !== 7) &&
                    (
                        <div>
                            <select
                                name="deliveryboy"
                                value={deliveryboy || ''}
                                {...row.order_status_code == 3 && { disabled: true }}
                                onChange={this.handleInputChange}
                                className="text-capitalize">
                                <option value="">Select Driver</option>
                                {data.drivers &&
                                    data.drivers.map((driver: any, index: any) => (
                                        // To lock local rider after one order dispatched
                                        // ((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1 && (driver.goPanda == "true" || ((driver.goPanda == "false" && !driver.order_id) || (driver.order_id && driver.order_id == row.order_id)))) ?
                                        ((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1) ?
                                            <option key={index} value={driver.id}>{driver.user_name.length > 15 ? `${driver.user_name.substring(0, 15)}...` : driver.user_name}</option>
                                            : ""
                                    ))
                                }
                            </select>
                            <br />
                            {row.order_status_code != 3 && <span className="badge badge-pill badge-info" style={{ cursor: 'pointer' }} onClick={this.handleSave}>save</span>}
                        </div>
                    )
                }
            </div>
        );
    }
}
class StatusEditor extends React.Component<{ data: any, row: any }, { [x: number]: any, cancelReason: any, orderstatus: any, selectedOrderId: any }> {
    openModal: any;
    closeModal: any;
    constructor(props: any) {
        super(props);
        this.state = {
            orderstatus: "",
            cancelReason: "",
            selectedOrderId: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentRef: any;
    printElement: any;
    componentDidMount() {
        const { row } = this.props
        this.setState({ orderstatus: row.order_status_code })
    }
    handleSave = () => {
        const { row } = this.props
        const { orderstatus } = this.state;
        let orderDate = moment(row.date_created).local().format('DD/MM/YYYY HH:mm:ss')
        let currentDate = moment().format('DD/MM/YYYY HH:mm:ss');
        let orderDateforComp = moment(row.date_created).local().format('YYYY-MM-DD');
        let currentDateforComp = moment().format('YYYY-MM-DD');
        var createdTime = moment(row.date_created).local().format('HH:mm');
        var currentTime = moment().local().format('HH:mm');
        var startTime = moment(createdTime, "HH:mm");
        var endTime = moment(currentTime, "HH:mm");
        var duration: any = moment.duration(endTime.diff(startTime));
        var minutes = parseInt(duration.asMinutes());
        var minutesduration = 0;
        if (moment(currentDateforComp).isAfter(orderDateforComp)) {
            var ms = moment(currentDate, "DD/MM/YYYY HH:mm:ss").diff(moment(orderDate, "DD/MM/YYYY HH:mm:ss"));
            var d = moment.duration(ms);
            minutesduration = Math.floor(d.asMinutes());
        } else {
            minutesduration = minutes;
        }
        let deliverytime: any = row.delivery_time_json != null ? JSON.parse(row.delivery_time_json) : { pending: 0, kitchen: 0, ready: 0, dispatched: 0 }
        if (row.delivery_status == "Delivery") {
            if (orderstatus == row.order_status_code) {
                toast.error("Please change the status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            } else {
                if (orderstatus == 3) {
                    if (row.delivery_boy !== "" && row.delivery_boy !== null) {
                        deliverytime["ready"] = minutesduration - (deliverytime.pending + deliverytime.kitchen);
                        const data = {
                            order_status_code: orderstatus,
                            delivery_status: row.delivery_status,
                            delivery_time_json: JSON.stringify(deliverytime)
                        }
                        this.props.data.updateunresolvedOrder(row.order_id, data, this.props.data.history)
                    } else {
                        alert('Please Assign the order to driver first')
                    }
                } else if (orderstatus == 6) {
                    this.openModal.click();
                } else {
                    if (orderstatus == 2) {
                        deliverytime["pending"] = minutesduration;
                    }
                    if (orderstatus == 4) {
                        deliverytime["dispatched"] = minutesduration - (deliverytime.pending + deliverytime.kitchen);
                    }
                    if (orderstatus == 8) {
                        deliverytime["kitchen"] = minutesduration - deliverytime.pending;
                    }
                    const data: any = {
                        order_status_code: orderstatus,
                        delivery_status: row.delivery_status,
                        delivery_time_json: JSON.stringify(deliverytime)
                    }
                    this.props.data.updateunresolvedOrder(row.order_id, data, this.props.data.history)
                }
            }
        } else {
            const data: any = {
                order_status_code: orderstatus,
                delivery_status: row.delivery_status
            }
            if (orderstatus == row.order_status_code) {
                toast.error("Please change the status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            } else {
                if (orderstatus == 6) {
                    this.openModal.click();
                } else {
                    this.props.data.updateunresolvedOrder(row.order_id, data, this.props.data.history)
                }
            }
        }
        if (orderstatus == 2 && row.order_status_code !== 2) {
            this.printElement.handleClick();
        }
    };
    isCancelOrderReady = () => {
        const { cancelReason } = this.state;
        return (cancelReason !== "");
    }
    cancelOrder(order_id: any) {
        const data: any = {
            order_status_code: 6,
            cancel_reason: this.state.cancelReason
        }
        this.props.data.updateunresolvedOrder(order_id, data, this.props.data.history);
        this.closeModal.click();
    }
    calcSubTotalTotal = (orderItems: any) => {
        let sum = 0;
        orderItems.forEach((item: any) => {
            sum += parseInt(item.order_item_grossprice);
        });
        return Math.round(sum);
    };
    calcTotal = (orderItems: any) => {
        let sum = 0;
        orderItems.forEach((item: any) => {
            sum += parseInt(item.order_item_grossprice);
        });
        return Math.round(sum);
    };
    showorderItems = (id: any) => {
        this.props.data.stopTimer();
        let storeId: any = "";
        let stateId: any = "";
        let tax: any = "";
        this.props.data.showorderItems(id, this.props.data.orders);
        storeId = this.props.data.orders.find((obj: any) => {
            return obj.order_id == id;
        }).store_id;
        if (storeId && storeId !== "") {
            stateId = this.props.data.stores.find((obj: any) => {
                return obj.store_id == storeId;
            }).state_id;
        }
        this.setState({ selectedOrderId: id });
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        let { row } = this.props;
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.value == 2) {
            this.showorderItems(row.order_id);
        }
    }

    render() {
        const { data, row } = this.props;
        const { orderstatus } = this.state;
        let DateCreated: any = "";
        let FutureDate: any = "";
        let utcDateTime: any = "";
        if (data.orderDetail) {
            DateCreated = moment(data.orderDetail.date_created).local().format('YYYY-MM-DD HH:mm').split(' ');
            DateCreated = DateCreated[1] + " , " + DateCreated[0];
        }
        let FbrNumber: any = "";
        let FbrNumberComplete: any = "";
        if (data.orderDetail) {
            DateCreated = moment(data.orderDetail.date_created).local().format('YYYY-MM-DD HH:mm').split(' ');
            DateCreated = DateCreated[1] + " , " + DateCreated[0];

            if (data.orderDetail.fbr_invoice_number != undefined) {
                FbrNumberComplete = data.orderDetail.fbr_invoice_number;
                FbrNumber = FbrNumberComplete.split(':');
                FbrNumber = FbrNumber[1];
                console.log("FbrNumber", FbrNumber);
            }
        }
        if (data.orderDetail && data.orderDetail.future_date) {
            utcDateTime = moment(data.orderDetail.future_date).utc(false);
            FutureDate = moment(utcDateTime).local().format('YYYY-MM-DD HH:mm').split(' ');
            FutureDate = FutureDate[1] + " , " + FutureDate[0];
        }
        return (
            <div>
                {(row.order_status_code === 4 || row.order_status_code === 5 || row.order_status_code === 6) ? <span {...(((row.order_status_code === 4 || row.order_status_code === 5) && { className: "badge badge-success text-capitalize p-1" }) || (row.order_status_code === 6 && { className: "badge badge-danger text-capitalize p-1" }))}>{row.order_status_description}</span> :
                    <div>
                        <select
                            name="orderstatus"
                            value={orderstatus}
                            onChange={this.handleInputChange}
                            className="text-capitalize">
                            {data.statusList &&
                                data.statusList.map((status: any, index: any) => (
                                    (row.future_status == 1) ?
                                        (row.future_status == 1 && row.orderStatus == "future" && row.order_status_code == 1) ?
                                            (status.order_status_code != 2 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 6 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                            : (row.future_status == 1 && row.orderStatus == "future" && row.order_status_code == 7) ?
                                                (status.order_status_code != 1 && status.order_status_code != 2 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 6 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option> :
                                                (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 1) ?
                                                    (status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 6 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option> :
                                                    (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 7) ?
                                                        (status.order_status_code != 1 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 6 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        : (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 2) ?
                                                            (row.delivery_status == "Delivery")
                                                                //if order is delivery,so Delivered option will be show instead of picked
                                                                ?
                                                                (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 6 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                //else if order is pick up,so Picked option will be show instead of picked
                                                                :
                                                                (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 6 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option> :
                                                            (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 8) ?
                                                                //then 2.3
                                                                (row.delivery_status == "Delivery")
                                                                    ?
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 6 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                    :
                                                                    //else 2.3
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code != 6 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                                :
                                                                (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 3) &&
                                                                    (row.delivery_status == "Delivery")
                                                                    ?
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 5 && status.order_status_code != 6 && status.order_status_code != 7 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                    :
                                                                    //else 2.3
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code != 6 && status.order_status_code != 7 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                        :
                                        row.order_status_code == 1 ?
                                            //if order status is 1 so then
                                            (status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 6 && status.order_status_code != 7 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                            //if status is not 1 so then
                                            :
                                            //if status is 2
                                            (row.order_status_code == 2) ?
                                                (row.delivery_status == "Delivery")
                                                    //if order is delivery,so Delivered option will be show instead of picked
                                                    ?
                                                    (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 6 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                    //else if order is pick up,so Picked option will be show instead of picked
                                                    :
                                                    (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 6 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                //Nested else if order status is 3
                                                : (row.order_status_code == 8) ?
                                                    //then 2.3
                                                    (row.delivery_status == "Delivery")
                                                        ?
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 6 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        :
                                                        //else 2.3
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code != 6 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                    : (row.order_status_code == 3) &&
                                                        //then 2.3
                                                        (row.delivery_status == "Delivery")
                                                        ?
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 5 && status.order_status_code != 6 && status.order_status_code != 7 && status.order_status_code !== 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        :
                                                        //else 2.3
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code != 6 && status.order_status_code != 7 && status.order_status_code !== 8) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                ))
                            }
                        </select>
                        <br />
                        {data.buttonDisable==false &&<span className="badge badge-pill badge-info text-capitalize" style={{ cursor: 'pointer' }} onClick={() => this.handleSave()}>save</span>}
                    </div>
                }
                {/* Print Preview */}
                <div style={{ display: "none" }}>
                    <div className="d-flex justify-content-end">
                        <ReactToPrint
                            ref={el => this.printElement = el}
                            //trigger={() => <button type="button" className="btn btn-success">Print</button>}
                            content={() => this.componentRef}
                        />
                    </div>
                    <div style={{ width: '108mm' }} ref={el => (this.componentRef = el)}>
                        <header className="clearfix">
                            <div id="logo">
                                <img width={80} height={80} style={{ borderRadius: 10 }} src={process.env.PUBLIC_URL + "/assets/img/logoOptp"} />
                            </div>
                            <h6 className="text-center mt-3"><b>One Potato Two Potato</b></h6>
                            <h6 className="text-center mt-3">{data.orderDetail.city}</h6>
                            <div id="project" className="invoiceh1">
                                <div style={{ display: 'flex' }}><span><b>ORDER NO:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {row.order_id}</span></div>
                                <div style={{ display: 'flex' }}><span><b>Order Date:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}> {data.orderDetail && DateCreated}</span></div>
                                <div style={{ display: 'flex' }}><span style={{ textAlign: "left" }}><b>Customer:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.login_name}</span></div>
                                {(data.orderDetail && data.orderDetail.phone_number) && <div style={{ display: 'flex' }}><span style={{ textAlign: "left" }}><b>Phone</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail.phone_number}</span></div>}
                                <div style={{ display: 'flex' }}><span style={{ textAlign: "left" }}><b>Store:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.store_name}</span></div>
                                <div style={{ display: 'flex' }}><span style={{ textAlign: "left" }}><b>From:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.address}</span></div>
                                {(data.orderDetail && data.orderDetail.delivery_address) && <div style={{ display: 'flex' }}><span style={{ textAlign: "left" }}><b>Delivery at:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail.delivery_address}</span></div>}
                                <div style={{ display: 'flex' }}><span><b>Special Ins:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{data.orderDetail && data.orderDetail.special_inst}</span></div>
                                {utcDateTime._isValid && <div><span style={{ textAlign: "left" }}><b>{data.orderDetail && data.orderDetail.delivery_status === "Delivery" ? 'Delivery' : 'Pickup'} Time</b></span><br /><span>{FutureDate}</span></div>}
                                {FbrNumber && <div style={{ display: 'flex' }}><span><b>FBR Invoice:</b></span><span style={{ whiteSpace: "normal", textAlign: 'left', flex: 'auto' }}>{FbrNumber}</span><span style={{ whiteSpace: "normal", textAlign: 'right', flex: 'auto' }}><QRCode size='50' value={FbrNumber} /></span></div>}
                            </div>
                            <div id="secProject">
                                <div id="project2">
                                    <div><h6 style={{ fontSize: 13 }}>Payment Type</h6>{data.orderDetail && data.orderDetail.payment_method}</div>
                                </div>
                                <div id="project2">
                                    <div>
                                        <h6 style={{ fontSize: 13 }}>Ordering Channel</h6>{data.orderDetail && data.orderDetail.order_channel}
                                    </div>
                                </div>
                                <div id="project2">
                                    <div>
                                        <h6 style={{ fontSize: 13 }}>Ordering Mode</h6>{data.orderDetail && data.orderDetail.delivery_status}
                                    </div>
                                </div>
                            </div>
                        </header>
                        <main>
                            <table className="invoicetable">
                                <thead>
                                    <tr>
                                        {/* <th className="service"></th> */}
                                        <th className="desc">Name</th>
                                        {/* <th className="desc">DESCRIPTION</th> */}
                                        {/* <th>PRICE</th> */}
                                        {/* <th style={{ width: 50 }}>QTY</th> */}
                                        <th style={{ width: 100 }}>TOTAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.Items.map((obj: any, index: any) => {
                                        let AddOnsPrice: any = 0;
                                        const ingredients = obj.combo_choices && JSON.parse(obj.combo_choices).map((element: any) => {
                                            return <li><b>{element.quantity*obj.order_item_quantity} </b>{element.item_name} ({element.size && JSON.parse(element.size).size}){element.extra_price > 0 && `+${currency}.${element.extra_price*obj.order_item_quantity}`}</li>
                                        })
                                        return (
                                            <>
                                                <tr key={index}>
                                                    {/* <td className="service">{index + 1}</td> */}
                                                    <td className="desc" title={(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)}><b>{(obj.combo_name !== "" && obj.combo_name) || (obj.item_name !== "" && `${obj.item_name}(${JSON.parse(obj.item_size).size})`)} x {obj.order_item_quantity}</b>
                                                        <ul>
                                                            {ingredients}
                                                            <br />
                                                            <b>{(obj.modifiers && JSON.parse(obj.modifiers).length > 0) && "Add Ons"}</b>
                                                        </ul>
                                                    </td>
                                                    {/* <td className="desc" title={modifiers}>{modifiers}</td> */}
                                                    {/* <td className="unit">{Math.round(obj.order_item_grossprice / obj.order_item_quantity)}</td> */}
                                                    {/* <td className="qty">{obj.order_item_quantity}</td> */}
                                                    <td className="total">{obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                        AddOnsPrice += (element.quantity * element.modifier_sale_price);
                                                    })}
                                                      {currency}.{obj.order_item_grossprice - AddOnsPrice}</td>
                                                </tr>

                                                {
                                                    obj.modifiers && JSON.parse(obj.modifiers).map((element: any) => {
                                                        return <tr><td style={{ textAlign: "left" }}><b>{element.quantity} </b>{element.modifier_name}</td><td>{currency}.{element.modifier_sale_price * element.quantity}</td></tr>
                                                    })
                                                }
                                                <tr><td>Total</td><td>{currency}.{obj.order_item_grossprice}</td></tr>
                                            </>
                                        )
                                    })}

                                    <tr>
                                        <td colSpan={1}>SUBTOTAL</td>
                                        <td className="total">{currency}.{data.Items.length > 0 && this.calcSubTotalTotal(data.Items)}</td>
                                    </tr>
                                    {(data.orderDetail && data.orderDetail.delivery_status == "Delivery") && <tr>
                                        <td colSpan={1}>Delivery Charges</td>
                                        <td className="total">{currency}.{data.orderDetail && data.orderDetail.delivery_fee}</td>
                                    </tr>
                                    }
                                    {data.orderDetail.coupon &&
                                        <tr>
                                            <td colSpan={1}>Discount</td>
                                            <td>{currency}.{Math.round(data.orderDetail.coupon_discount_value)} </td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                        </tr>
                                    }
                                    <tr>
                                        <td colSpan={1}>{Math.round(data.orderDetail.tax_percent)}% Tax</td>
                                        <td>{currency}.{Math.round(data.orderDetail.tax_amount)}</td> {/* {data.Items.length > 0 && Math.round((this.calcSubTotalTotal(data.Items) * tax)/100)} PKR */}
                                    </tr>
                                    <tr>
                                        <td colSpan={1} className="grand total">GRAND TOTAL</td>
                                        <td className="grand total">{currency}.{(data.Items.length > 0 && data.orderDetail) && (this.calcTotal(data.Items) - data.orderDetail.coupon_discount_value) + data.orderDetail.delivery_fee + Math.round(data.orderDetail.tax_amount)} </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* <p className="text-center mt-3">The Potato Factory International</p>
                            <p className="text-center mt-3">NTN # {data.orderDetail.ntn_number}</p>
                            <p className="text-center mt-3"></p>
                            <h6 className="text-center mt-3">{data.orderDetail.contact1}</h6> */}
                            <footer className="invoicefooter">
                                "Thank you for Ordering"
                        </footer>
                        </main>
                    </div>

                </div>
                {/* End Print Preview */}
                <div style={{ display: "none" }}>
                    <button ref={el => this.openModal = el} data-toggle="modal" data-target={`#viewcancel${row.order_id}`} className="btn btn-outline-info ml-2"><i className="fa fa-file-pdf-o"></i></button>
                </div>
                <div id={`viewcancel${row.order_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Cancel Order</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h6>Are you sure,you want to cancel the order?</h6>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <select
                                                name="cancelReason"
                                                onChange={this.handleInputChange}
                                                className="form-control mt-2">
                                                <option value="">Select Reason</option>
                                                {data.cancelReasons &&
                                                    data.cancelReasons.map((reason: any, index: any) => (
                                                        <option key={index} value={reason.id}>{reason.reason}</option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-group d-flex justify-content-end mt-4">
                                    <button className='btn btn-primary' disabled={!this.isCancelOrderReady()} onClick={() => this.cancelOrder(row.order_id)}>Save</button>
                                </div>
                            </div>
                            <div style={{ display: "none" }}>
                                <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const divStyle = {
    margin: '0px',
    padding: '15px'
};

class ActionFormatter extends Component<{ row: any }, { selectedOrderId: any, tax: any, }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            selectedOrderId: "",
            tax: ""
        }
    }
    showorderItems = (id: any) => {
        this.props.data.stopTimer();
        let storeId: any = "";
        let stateId: any = "";
        let tax: any = "";
        this.props.data.showorderItems(id, this.props.data.orders);
        storeId = this.props.data.orders.find((obj: any) => {
            return obj.order_id == id;
        }).store_id;
        if (storeId && storeId !== "") {
            stateId = this.props.data.stores.find((obj: any) => {
                return obj.store_id == storeId;
            }).state_id;
        }
        if (stateId !== "") {
            tax = this.props.data.states.find((obj: any) => {
                return obj.state_id == stateId;
            }).tax_percent;
        }
        this.setState({ selectedOrderId: id, tax: tax });
    }
    render() {
        const { row, data } = this.props;
        return (
            <div>
                {/* <button title="View Order Items" data-toggle="modal" data-target={`#viewitems${row.order_id}`} className="btn btn-outline-info" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-list"></i></button> */}
                <button title="View Invoice" className="btn btn-outline-info ml-2" data-toggle="modal" data-target={`#viewinvoice${row.order_id}`} onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
                {/* {(row.fbr_invoice_number != "") ?
                    <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice${row.order_id}`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
                    : <button title="View Invoice" data-toggle="modal" className="btn btn-outline-danger ml-2"><i className="fa fa-file-o"></i></button>
                } */}
                {/* <!-- Modal--> */}
                <div id={`viewitems${row.order_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Order Items</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="row" style={divStyle}>
                                <div className="col-6 to">
                                    <h3>Customer Information</h3>
                                    <span className="d-block font-weight-light">Customer Name: </span>
                                    <span className="d-block font-weight-light">Phone Number: </span>
                                    <span className="d-block font-weight-light">Email Address: </span>
                                    {data.Items.length > 0 ? data.Items[0].address_line_1 && <span className="d-block font-weight-light">Address 1: </span> : ""}
                                </div>
                                <div className="col-6 to">
                                    <h3><br /></h3>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].first_name} {data.Items.length > 0 && data.Items[0].last_name}</span>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].phone_number}</span>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].email_address}</span>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].address_line_1}</span>

                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">

                                            {(data.Items.length > 0) ?

                                                <BootstrapTable data={data.Items} hover>

                                                    <TableHeaderColumn dataField="order_item_id" dataAlign="center" width="80" columnTitle isKey>Order Item Id</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="order_item_quantity" width="100" columnTitle>Quantity</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="item_name" width="150" columnTitle>Item Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="combo_name" width="150" columnTitle>Combo Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="order_item_grossprice" width="60" columnTitle>Amount</TableHeaderColumn>

                                                </BootstrapTable> :
                                                <p className="text-center">Order Items Not Found</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Invoice Modal */}
                <div id={`viewinvoice${row.order_id}`} data-backdrop='static' role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Invoice</h4>
                                <button type="button" onClick={() => { this.props.data.luanchTimer(this.props.data.history); }} data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <Invoice data={data} orderId={this.state.selectedOrderId} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function priceFormatter(cell: any,row:any) {
    if(row.aggregator_orderId){
        return `${currency}.${cell}`;
    }else{
        return `${currency}.${Math.round(cell)}`;
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}
const selectDriverEditor = (cell: any, row: any, props: any) => {
    return (
        row.delivery_status == "Delivery" ?
            <DriverEditor row={row} data={props} /> : ""
    )
}
const selectStatusEditor = (cell: any, row: any, props: any) => {
    return (
        <StatusEditor row={row} data={props} />
    )
}

class UnresolvedOrders extends Component<OrderProps, { days: any,startDate: any, endDate: any,validTimeFlag: any, [x: number]: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            days: 1,
            startDate: "",
            endDate: "",
            validTimeFlag: ""
        }
        this.rowClassNameFormat = this.rowClassNameFormat.bind(this);
        this.handleDaysFilter = this.handleDaysFilter.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidTime = this.handleValidTime.bind(this)
    }
    componentDidMount() {
        let obj = { days: this.state.days };
        this.props.unresolvedordersList(obj);
        this.props.orderStatusList();
        this.props.statesList();
        this.props.cancelOrdersReasons();
        // this.props.luanchTimer(this.props.history);
        document.title = "SimplexHDS | Orders"
    }
    rowClassNameFormat(row: any, rowIdx: any) {
        // row is whole row object
        // rowIdx is index of row
        return row.isNew == true ? 'td-column-function-even-example' : row.future_status == 1 ? 'td-column-function-odd-example' : "";
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        let prevOrdersLength: any = sessionStorage.getItem("prevLength");
        if (nextProps.orders.length > 0) {
            var audio = new Audio();
            audio.src = process.env.PUBLIC_URL + "/assets/sound/old-style-phone-ringing.mp3";
            // if (prevOrdersLength < nextProps.orders.length) {
            nextProps.orders.map((element: any) => {
                if (element.future_status == 1) {
                    if (element.order_status_code == 1 && element.orderStatus == "future") {
                        // audio.play();
                        audio.oncanplaythrough = (event) => {
                            var playedPromise = audio.play();
                            if (playedPromise) {
                                playedPromise.catch((e) => {
                                    console.log(e)
                                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                                        console.log(e.name);
                                    }
                                }).then(() => {
                                    console.log("playing sound !!!");
                                    return
                                });
                            }
                        }
                    } else if (element.order_status_code == 7 && element.orderStatus == "now") {
                        audio.oncanplaythrough = (event) => {
                            var playedPromise = audio.play();
                            if (playedPromise) {
                                playedPromise.catch((e) => {
                                    console.log(e)
                                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                                        console.log(e.name);
                                    }
                                }).then(() => {
                                    console.log("playing sound !!!");
                                    return
                                });
                            }
                        }
                    }
                } else {
                    if (element.order_status_code == 1) {
                        // audio.play();
                        audio.oncanplaythrough = (event) => {
                            var playedPromise = audio.play();
                            if (playedPromise) {
                                playedPromise.catch((e) => {
                                    console.log(e)
                                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                                        console.log(e.name);
                                    }
                                }).then(() => {
                                    console.log("playing sound !!!");
                                    return
                                });
                            }
                        }

                    }
                }
            });
        // }
        }
    }
    handleDaysFilter(event: { target: { name: any; value: any; }; }) {
        let obj = { days: event.target.value };
        this.props.ordersList(obj);
        // console.log("event.target.value",event.target.value);
    }
    FilterbyStatus(status_code: any) {
        let { orders } = this.props;
        let filteredArr: any = [];
        if (status_code === 4) {
            filteredArr = orders.filter((order: any) => order.order_status_code == status_code || order.order_status_code == 5)
        } else if (status_code === 3) {
            filteredArr = orders.filter((order: any) => order.order_status_code == status_code || order.order_status_code == 8)
        }
        else {
            filteredArr = orders.filter((order: any) => order.order_status_code == status_code)
        }
        this.props.filterOrdersList(filteredArr)
    }
    handleValidTime(event: { target: { name: any; value: any; }; }) {
        let futureDatetime = new Date(event.target.value)
        let validMinDateTime = new Date();
        if (futureDatetime <= validMinDateTime) {
            this.setState({ [event.target.name]: event.target.value, validTimeFlag: true });
        } else {
            this.setState({ validTimeFlag: false })
            toast.error("Invalid date and time selected");
        }
    }
    isOrderDateReady = () => {
        let { startDate, endDate } = this.state;
        return ( startDate !== "" && endDate !== "");
      }
    handleSubmit() {
        let { startDate, endDate } = this.state;
        let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
        let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
        let data: any = {
            start: startDateUtc,
            end: endDateUtc
        }
        this.setState({startDate:"",endDate:""})
        this.props.ordersList(data);
    }

    UnsuspendStore = () => {
        let {loginStore} = this.props;
        let id = loginStore.store_id
        let obj = {
            is_suspend: 0,
            is_active: 1
        }
        this.props.UnsuspendStore(id, obj, this.props.history)
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let maxDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
        let { orders, filteredOrders, emptyFilteredOrder, avg, dayVal,loginStore, kitchenCount, recievedOrderCount, readyOrderCount, completedOrderCount,confirmedOrderCount } = this.props;
        console.log("Avg time: ", avg);
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: 'Orders Not Found',
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                            <div className="row py-2">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <h4 className="mt-2">Unresolved Orders Management</h4>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* Counters Section */}
                        <section className="dashboard-counts py-2 px-2">
                            <div className="container-fluid">
                                <div className="row bg-white has-shadow p-0">
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-3 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(1) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-green"><i className="fa fa-money"></i></div>
                                            <div className="title"><span>Pending</span><br />
                                                <strong className="text-bold text-dark">{recievedOrderCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${recievedOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-green"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{recievedOrderCount}</strong></div> */}
                                        </div>
                                    </div>

                                    {/* <!-- Item --> */}
                                    <div className="col-xl-3 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(2) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-blue"><i className="fa fa-cutlery"></i></div>
                                            <div className="title"><span>Kitchen</span> <br />
                                                <strong className="text-bold text-dark">{kitchenCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${kitchenCount / 100}%`, height: "4px" }} className="progress-bar bg-blue"></div>  {/*ariaValuenow="25" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{kitchenCount}</strong></div> */}
                                        </div>
                                    </div>
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-3 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(3) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-orange"><i className="fa fa-truck"></i></div>
                                            <div className="title"><span>Ready</span><br />
                                                <strong className="text-bold text-dark">{readyOrderCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${readyOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-orange"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{readyOrderCount}</strong></div> */}
                                        </div>
                                    </div>

                                    {/* <!-- Item --> */}
                                    <div className="col-xl-3 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(7) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-orange"><i className="fa fa-check"></i></div>
                                            <div className="title"><span>Confirmed</span><br />
                                                <strong className="text-bold text-dark">{confirmedOrderCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${confirmedOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-orange"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{readyOrderCount}</strong></div> */}
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </section>
                        {/* End Counters Section */}
                        {loginStore.is_suspend == 1 && orders.length == 0 &&
                        <section className="py-2 px-2">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    <div className="card">
                                            <div className="card-body">
                                                <button onClick={() => this.UnsuspendStore()} className="store btn btn-primary">
                                                    <i className="fa fa-refresh" aria-hidden="true"></i>
                                                    Reinstate Store                                                   
                                                </button>
                                            </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                        </section>
                        }

                        <section className="tables py-2">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={filteredOrders.length > 0 || emptyFilteredOrder ? filteredOrders : orders} trClassName={this.rowClassNameFormat} search={true} pagination={orders.length > 10 && true} options={options} hover>
                                                    <TableHeaderColumn dataField='order_id' thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} csvHeader='Order Id' dataSort={true} isKey>Order Id</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='action' thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Details</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='date_created' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Order Recieved' dataFormat={dateFormatter} columnTitle>Order Recieved</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='store_name' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Store' columnTitle>Store</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='delivery_status' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Order Type' columnTitle>Order Type</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='order_channel' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal',textTransform:'capitalize' }} columnTitle>Order Channel</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='delivery_time_json' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={calculateDeliveryTime} csvHeader='Delivery time' export={false}>Delivery Time</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='order_status_description' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10 }} dataFormat={selectStatusEditor} formatExtraData={this.props} csvHeader='Order Status'>Status</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='user_name' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10 }} dataFormat={selectDriverEditor} formatExtraData={this.props} csvHeader='Delivery Driver'>Delivery Driver</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='login_name' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Customer Name' columnTitle>Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='phone_number' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Phone' columnTitle>Phone</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='delivery_address' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Address' columnTitle>Address</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='order_grossprice' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={priceFormatter} csvHeader='Price'>Order Price</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='payment_method' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Payment Method' columnTitle>Payment Method</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='reason' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Cancel Reason' columnTitle>Cancel Reason</TableHeaderColumn>
                                                    {/* <TableHeaderColumn dataField='feedback' csvHeader='Feedback' width='150' columnTitle>Feedback</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='ratings' width='200' csvHeader='Rating' dataFormat={ratingsFormatter} columnTitle>Rating</TableHeaderColumn> */}
                                                    <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='fbr_invoice_number' csvHeader='FBR Invoice Number' columnTitle>FBR Invoice Number</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='check_number' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Check Number' columnTitle>Check Number</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
};
function dateFormatter(cell: any) {
    var DateCreated: any = moment(cell).local().format('YYYY-MM-DD HH:mm').split(' ');
    DateCreated = DateCreated[1] + " , " + DateCreated[0];
    return (
        <div>
            <span {...((cell !== "" && cell !== null) && { title: DateCreated })}> {(cell !== "" && cell !== null) && DateCreated} </span>
        </div>
    )
}
function calculateDeliveryTime(cell: any, row: any) {
    let DeliveryTime: any = row.delivery_time_json && JSON.parse(row.delivery_time_json);
    let ActualDeliveryTime = row.delivery_time_json ? DeliveryTime.pending + DeliveryTime.kitchen + DeliveryTime.ready + DeliveryTime.dispatched : 0
    return (
        <div>
            <span {...((row.order_status_code == 4 && row.delivery_time_json) && { title: `${ActualDeliveryTime} min` })}> {(row.order_status_code == 4 && row.delivery_time_json) && `${ActualDeliveryTime} min`} </span>
        </div>
    )
}
function ratingsFormatter(cell: any, row: any) {
    return (
        <BeautyStars
            value={cell}
            size="16"
            inactiveColor="#b7b7b7"
        />
    )
}
const mapStateToProps = (state: any) => {
    return {
        orders: state.order.data,
        filteredOrders: state.order.filteredOrders,
        emptyFilteredOrder: state.order.emptyFilterOrder,
        Items: state.order.orderItems,
        orderDetail: state.order.order,
        statusList: state.order.orderStatus,
        drivers: state.order.deliveryBoys,
        states: state.order.states,
        cancelReasons: state.order.cancelReasons,
        stores: state.order.stores,
        prevOrdersLength: state.order.prevOrdersLength,
        buttonDisable: state.order.buttonDisable,
        kitchenCount: state.order.kitchenCount,
        recievedOrderCount: state.order.recievedOrderCount,
        readyOrderCount: state.order.readyOrderCount,
        completedOrderCount: state.order.completedOrderCount,
        confirmedOrderCount: state.order.confirmedOrderCount,
        dayVal: state.order.dayVal,
        avg: state.order.avg,
        loginStore: state.order.loginStore,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        unresolvedordersList: function (obj: any) {
            dispatch(unresolvedordersList(obj))
        },
        showorderItems: function (id: any, orders: any, channel:any) {
            dispatch(showorderItems(id, orders, channel))
        },
        cancelOrdersReasons: () => {
            dispatch(cancelOrdersReasons())
        },
        orderStatusList: function () {
            dispatch(orderStatusList())
        },
        updateunresolvedOrder: (id: any, data: any, history: any) => {
            dispatch(updateunresolvedOrder(id, data, history))
        },
        UnsuspendStore: (id: any, data: any, history: any) => {
            dispatch(UnsuspendStore(id, data, history))
        },
        updatefutureOrder: (id: any, data: any, history: any) => {
            dispatch(updatefutureOrder(id, data, history))
        },
        statesList: function () {
            dispatch(statesList())
        },
        luanchTimer: (history: any) => {
            dispatch(luanchTimer(history))
        },
        stopTimer: () => {
            dispatch(stopTimer())
        },
        filterOrdersList: (filteredArr: any) => {
            dispatch(filterOrdersList(filteredArr))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UnresolvedOrders);