import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { cancelOrdersReasons, logoutUser, luanchTimer, ordersList, orderStatusList, showorderItems, statesList, stopTimer, storesList, updatefutureOrder, updateOrder } from '../../redux'
import { OrderProps } from '../../interfaces/order';
import BeautyStars from 'beauty-stars';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { filterOrdersList, searchOrder } from '../../redux/actions/orderAction';
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import { currency } from '../../client-config';
import { ReactTimer } from './timer/timer';
import Invoice from './invoice';
import InvoiceAggregator from './invoice-aggregator';
import HallaInvoice from './HallaInvoice';
import './orders.css'
var QRCode = require('qrcode.react');
toast.configure();

class DriverEditor extends React.Component<{ data: any, row: any,driver_state:any }, { [x: number]: any, deliveryboy: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            deliveryboy: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        const { row } = this.props
        this.setState({ deliveryboy: row.delivery_boy })
    }
    handleSave = () => {
        
        const { row } = this.props
        const { deliveryboy } = this.state;
        const data = {
            delivery_boy: deliveryboy ? deliveryboy : "",
            channel: row.order_channel ? row.order_channel : "",
        }
        if (deliveryboy !== "" && deliveryboy > 0) {
            this.props.data.updateOrder(row.order_id, data, this.props.data.history)
        } else {
            this.setState({ deliveryboy: row.delivery_boy })
            toast.error("Please select the driver", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
        }

    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        const { data, row } = this.props
        const { deliveryboy } = this.state;
        return (
            <div>
                {(row.order_status_code === 4 || row.order_status_code === 5 || row.order_status_code === 6 ||row.order_status_code === 10 || row.order_status_code == 9) ? <p>{row.user_name}</p> :
                    (row.order_status_code !== 1 && row.order_status_code !== 2 && row.order_status_code !== 7) &&
                    (
                        <div>
                            <select
                                name="deliveryboy"
                                // {...row.order_status_code == 3 && { disabled: true }}
                                {...(row.order_status_code == 3 || row.aggregator_delivery == 1) && { disabled: true }}
                                value={deliveryboy || ''}
                                onChange={this.handleInputChange}
                                className="text-capitalize">
                                <option value="">Select Driver</option>
                                {data.drivers &&
                                    data.drivers.map((driver: any, index: any) => (
                                        // To lock local rider after one order dispatched
                                        // ((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1 && (driver.goPanda == "true" || ((driver.goPanda == "false" && !driver.order_id) || (driver.order_id && driver.order_id == row.order_id)))) ?
                                        // ((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1) ?
                                        (((driver.store_id && row.store_id == driver.store_id.store_id) && driver.clockIn == 1) || driver.store_id == null) ?
                                            <option key={index} value={driver.id}>{driver.user_name.length > 15 ? `${driver.user_name.substring(0, 15)}...` : driver.user_name}</option>
                                            : ""
                                    ))
                                }
                            </select>
                            <br />
                            {/* {(row.order_status_code != 3 && row.aggregator_delivery == null) && <span className="badge badge-pill badge-info" style={{ cursor: 'pointer' }} onClick={this.handleSave}>save</span>} */}
                            {/* {(row.order_status_code != 3 && row.aggregator_delivery == 0) && <span className="badge badge-pill badge-info" style={{ cursor: 'pointer' }} onClick={this.handleSave}>save</span>} */}
                            {(row.order_status_code != 3 || row.aggregator_delivery == 0) && <span className="badge badge-pill badge-info" style={{ cursor: 'pointer' }} onClick={this.handleSave}>save</span>}
                        </div>
                    )
                }
            </div>
        );
    }
}
class StatusEditor extends React.Component<{ data: any, row: any,order_state:any }, { [x: number]: any, cancelReason: any, orderstatus: any, otherReason: any }> {
    openModal: any;
    closeModal: any;
    constructor(props: any) {
        super(props);
        this.state = {
            orderstatus: "",
            cancelReason: "",
            otherReason: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
    }
    componentDidMount() {
        const { row } = this.props
        this.setState({ orderstatus: row.order_status_code })
    }
    handleSave = () => {
        
        const { row } = this.props
        // console.log("in save line 116",row)
        const { orderstatus } = this.state;
        // let orderDate = moment(row.date_created).local().format('DD/MM/YYYY HH:mm:ss')
        // let currentDate = moment().format('DD/MM/YYYY HH:mm:ss');
        // let orderDateforComp = moment(row.date_created).local().format('YYYY-MM-DD');
        // let currentDateforComp = moment().format('YYYY-MM-DD');
        // var createdTime = moment(row.date_created).local().format('HH:mm');
        // var currentTime = moment().local().format('HH:mm');
        // var startTime = moment(createdTime, "HH:mm");
        // var endTime = moment(currentTime, "HH:mm");
        // var duration: any = moment.duration(endTime.diff(startTime));
        // var minutes = parseInt(duration.asMinutes());
        // var minutesduration = 0;
        // if (moment(currentDateforComp).isAfter(orderDateforComp)) {
        //     var ms = moment(currentDate, "DD/MM/YYYY HH:mm:ss").diff(moment(orderDate, "DD/MM/YYYY HH:mm:ss"));
        //     var d = moment.duration(ms);
        //     minutesduration = Math.floor(d.asMinutes());
        // } else {
        //     minutesduration = minutes;
        // }
        // let deliverytime: any = row.delivery_time_json != null ? JSON.parse(row.delivery_time_json) : { pending: 0, kitchen: 0, ready: 0, dispatched: 0 }
        if (row.delivery_status == "Delivery") {
            if (orderstatus == row.order_status_code) {
                toast.error("Please change the status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            } else {
                if (orderstatus == 3) {
                    if (row.delivery_boy !== "" && row.delivery_boy !== null) {
                        // deliverytime["ready"] = minutesduration - (deliverytime.pending + deliverytime.kitchen);
                        const data = {
                            order_status_code: orderstatus,
                            delivery_status: row.delivery_status,
                            orderData: row,
                            channel:row.order_channel
                        }
                        // console.log("data",this.props.order_state.brand_id,data)
                        this.props.data.updateOrder(row.order_id, data, this.props.data.history)
                    } else {
                        alert('Please Assign the order to driver first')
                    }
                } else if (orderstatus == 6) {
                    this.openModal.click();
                } else {
                    // if (orderstatus == 2) {
                    //     deliverytime["pending"] = minutesduration;
                    // }
                    // if (orderstatus == 4) {
                    //     deliverytime["dispatched"] = minutesduration - (deliverytime.pending + deliverytime.kitchen);
                    // }
                    // if (orderstatus == 8) {
                    //     deliverytime["kitchen"] = minutesduration - deliverytime.pending;
                    // }
                    const data: any = {
                        order_status_code: orderstatus,
                        delivery_status: row.delivery_status,
                        orderData: row,
                        channel:row.order_channel
                    }
                    // console.log("data",this.props.order_state.brand_id,data)
                    this.props.data.updateOrder(row.order_id, data, this.props.data.history)
                }
            }
        } else {
            const data: any = {
                order_status_code: orderstatus,
                delivery_status: row.delivery_status,
                channel:row.order_channel
            }
            if (orderstatus == row.order_status_code) {
                toast.error("Please change the status", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
            } else {
                if (orderstatus == 6) {
                    this.openModal.click();
                } else {
                    console.log("99999999",this.props.order_state.brand_id,data)
                    this.props.data.updateOrder(row.order_id, data, this.props.data.history)
                }
            }
        }
    }
    isCancelOrderReady = () => {
        const { cancelReason } = this.state;
        return (cancelReason !== "");
    }
    cancelOrder(order_id: any,channel:any,row:any) {
        const data: any = {
            order_status_code: 6,
            cancel_reason: this.state.cancelReason,
            channel,
            orderData:row
        }
        if (this.state.otherReason !== "") {
            data.cancel_reason_description = this.state.otherReason;
        }
        this.props.data.updateOrder(order_id, data, this.props.data.history);
        this.closeModal.click();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        const { data, row } = this.props;
        const { orderstatus } = this.state;
        return (
            <div>
                {(row.order_status_code === 4 || row.order_status_code === 5 || row.order_status_code === 6 ||row.order_status_code === 10) ? <span {...(((row.order_status_code === 4 || row.order_status_code === 5) && { className: "badge badge-success text-capitalize p-1" }) || ((row.order_status_code === 6  || row.order_status_code === 10) && { className: "badge badge-danger text-capitalize p-1" }))}>{row.order_status_description}</span> :
                    <div>
                        <select
                            name="orderstatus"
                            value={orderstatus}
                            onChange={this.handleInputChange}
                            className="text-capitalize">
                            {/* {data.statusList &&
                                data.statusList.map((status: any, index: any) => (
                                    (row.future_status == 1) ?
                                        (row.future_status == 1 && row.orderStatus == "future" && row.future_date !== null && (row.order_status_code == 1 || row.order_status_code == 9 )) ?
                                            (status.order_status_code != 2 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                            : (row.future_status == 1 && row.orderStatus == "future" && row.future_date !== null && row.order_status_code == 7) ?
                                                (status.order_status_code != 1 && status.order_status_code != 2 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 8 && status.order_status_code != 9 && status.order_status_code != 10) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                : (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 1) ?
                                                    (status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 8) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option> :
                                                    (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 7) ?
                                                        (status.order_status_code != 1 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 8 && status.order_status_code != 9 && status.order_status_code != 10) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        : (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 2) ?
                                                            (row.delivery_status == "Delivery")
                                                                //if order is delivery,so Delivered option will be show instead of picked
                                                                ?
                                                                (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 7 && status.order_status_code != 9 && status.order_status_code != 10) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                //else if order is pick up,so Picked option will be show instead of picked
                                                                :
                                                                (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 7 && status.order_status_code != 9 && status.order_status_code != 10) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option> :
                                                            (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 8) ?
                                                                //then 2.3
                                                                (row.delivery_status == "Delivery")
                                                                    ?
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 7 && status.order_status_code != 9 && status.order_status_code != 10) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                    :
                                                                    //else 2.3
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code != 7 && status.order_status_code != 9 && status.order_status_code != 10) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                                :
                                                                (row.future_status == 1 && row.orderStatus == "now" && row.order_status_code == 3) &&
                                                                    (row.delivery_status == "Delivery")
                                                                    ?
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 5 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8 && status.order_status_code != 9 && status.order_status_code != 10) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Dispatched" : status.order_status_description}</option>
                                                                    :
                                                                    //else 2.3
                                                                    (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8 && status.order_status_code != 9 && status.order_status_code != 10) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Ready For Pickup" : status.order_status_description}</option>
                                        :
                                        row.order_status_code == 1 || row.order_status_code == 9 ?
                                            //if order status is 1 so then
                                            (status.order_status_code != 3 && status.order_status_code != 4 && status.order_status_code != 5 && status.order_status_code != 7 && status.order_status_code != 8 && status.order_status_code != 9 && status.order_status_code != 10) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                            //if status is not 1 so then
                                            :
                                            //if status is 2
                                            (row.order_status_code == 2) ?
                                                (row.delivery_status == "Delivery")
                                                    //if order is delivery,so Delivered option will be show instead of picked
                                                    ?
                                                    (status.order_status_code !== 1 && status.order_status_code != 3 &&  status.order_status_code != 4 && status.order_status_code != 9 && status.order_status_code != 10 && status.order_status_code != 5 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                    //else if order is pick up,so Picked option will be show instead of picked
                                                    :
                                                    (status.order_status_code !== 1 && status.order_status_code != 3 && status.order_status_code !== 4 &&  status.order_status_code !== 5 && status.order_status_code != 9 && status.order_status_code != 10 && status.order_status_code != 7) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                //Nested else if order status is 3
                                                : (row.order_status_code == 8) ?
                                                    //then 2.3
                                                    (row.delivery_status == "Delivery")
                                                        ?
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code != 7 && status.order_status_code != 9 && status.order_status_code != 10) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        :
                                                        //else 2.3
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code != 7 && status.order_status_code != 9 && status.order_status_code != 10) && <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                    : (row.order_status_code == 3) &&
                                                        //then 2.3
                                                        (row.delivery_status == "Delivery")
                                                        ?
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 5 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8 && status.order_status_code != 9 && status.order_status_code != 10) && <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        :
                                                        //else 2.3
                                                        (status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 6 && status.order_status_code != 7 && status.order_status_code !== 8 && status.order_status_code != 9 && status.order_status_code != 10) && <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Ready To Pickup" : status.order_status_description}</option>
                                ))
                            } */}
                            {/* new code with hunger station update */}
                            {data.statusList &&
                                data.statusList.map((status:any, index:any) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {(() => {
                                                if (row.future_status == 1 || row.orderStatus == "future" || row.future_date !== null && row.order_status_code !== 2 && row.order_status_code !== 8 && row.order_status_code !== 3 && row.order_status_code !== 11 ) {
                                                    if(row.delivery_status == "Delivery" && (row.order_status_code !== 2 && row.order_status_code !== 8 && row.order_status_code !== 3)){
                                                        if(row.future_status == 1 && row.orderStatus == "future" || row.future_date !== null){
                                                            if(row.order_status_code == 1){
                                                                if((status.order_status_code !== 2 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 6 && status.order_status_code !== 8 && status.order_status_code !== 9 && status.order_status_code !== 11)){
                                                                    return (
                                                                        <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                    )
                                                                }
                                                            }
                                                            if(row.order_status_code == 9){
                                                                if(status.order_status_code !== 1 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 7 && status.order_status_code !== 8 && status.order_status_code !== 10 && status.order_status_code !== 11){
                                                                    return (
                                                                        <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                    )
                                                                }
                                                            }
                                                            if(row.order_status_code == 7 && row.future_status == 1){
                                                                if(status.order_status_code !== 1 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 8 && status.order_status_code !== 9 && status.order_status_code !== 10 && status.order_status_code !== 11){
                                                                    return (
                                                                        <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                    )
                                                                }
                                                            }
                                                        }
                                                    } else if (row.orderStatus == "future" || row.future_date !== null && row.delivery_status !== "Delivery") {
                                                        if(row.order_status_code == 1){
                                                            if(status.order_status_code !== 2 &&status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 6 && status.order_status_code !== 8 && status.order_status_code !== 9 && status.order_status_code !== 11){
                                                                return (
                                                                    <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                )
                                                            }
                                                        }
                                                        if(row.order_status_code == 9 && row.future_status !== null ){
                                                            if(status.order_status_code !== 1 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 7 && status.order_status_code !== 8 && status.order_status_code !== 10 && status.order_status_code !== 11){
                                                                return (
                                                                    <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                )
                                                            }
                                                        }  
                                                        if(row.order_status_code == 7 && row.future_status !== null  ){
                                                            if(status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 8 && status.order_status_code !== 9 && status.order_status_code !== 10 && status.order_status_code !== 11){
                                                                return (
                                                                    <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                )
                                                            }
                                                        }     
                                                    }
                                                } if(row.future_status == 0 || row.orderStatus == "now" && row.order_status_code !== 2 && row.order_status_code !== 8 && row.order_status_code !== 3){
                                                    if (row.future_status == 0 && row.orderStatus == "now") {
                                                        // for hunger station and deliveroo status list
                                                        // if(row.order_status_code == 1 && (row.order_channel == "deliveroo" || row.order_channel == "Hungerstation")){
                                                        //     if(status.order_status_code !== 2 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 7 && status.order_status_code !== 8 && status.order_status_code !== 9 && status.order_status_code !== 10){
                                                        //         return (
                                                        //             <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        //         )
                                                        //     }
                                                        // }
                                                        // if(row.order_status_code == 9 && (row.order_channel == "deliveroo" || row.order_channel == "Hungerstation")){
                                                        //     if(status.order_status_code !== 1 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 7 && status.order_status_code !== 8 && status.order_status_code !== 10 && status.order_status_code !== 11){
                                                        //         return (
                                                        //             <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        //         )
                                                        //     }
                                                        // }
                                                        if(row.order_status_code == 1){
                                                            if(status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 7 && status.order_status_code !== 8 && status.order_status_code !== 9 && status.order_status_code !== 10 && status.order_status_code !== 11){
                                                                return (
                                                                    <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                )
                                                            }
                                                        }
                                                        if(row.order_status_code == 9){
                                                            if(row.order_status_code !== 1 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 7 && status.order_status_code !== 8 && status.order_status_code !== 9 && status.order_status_code !== 10 && status.order_status_code !== 11){
                                                                return (
                                                                    <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                )
                                                            }
                                                        } 
                                                        // if any chance to order confirm status
                                                        if(row.order_status_code == 7 && row.future_status == 0){
                                                            if(status.order_status_code !== 1 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 7 && status.order_status_code !== 8 && status.order_status_code !== 9 && status.order_status_code !== 10 && status.order_state_code !== 11){
                                                                return (
                                                                    <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                )
                                                            }
                                                        }
                                                        // if(row.order_status_code == 11 && (row.order_channel == "deliveroo" || row.order_channel == "Hungerstation")){
                                                        //     if(status.order_status_code !== 1 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 7 && status.order_status_code !== 8 && status.order_status_code !== 9 && status.order_status_code !== 10 ){
                                                        //         return (
                                                        //             <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                        //         )
                                                        //     }
                                                        // }
                                                    }
                                                } if (row.order_status_code == 2 && row.order_status_code !== 11) {
                                                    if(row.delivery_status == "Delivery"){
                                                        if(status.order_status_code !== 1 && status.order_status_code !== 3 &&  status.order_status_code !== 4 && status.order_status_code !== 5 && status.order_status_code !== 7 && status.order_status_code !== 9 && status.order_status_code !== 10 && status.order_status_code !== 11){
                                                            return (
                                                                <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                            )
                                                        }
                                                    } else if((status.order_status_code !== 1 && row.order_status_code == 2 && status.order_status_code !== 3 && status.order_status_code !== 4 &&  status.order_status_code !== 5 && status.order_status_code !== 7 && status.order_status_code !== 9 && status.order_status_code !== 10 && status.order_status_code !== 11)){
                                                        return (
                                                            <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                        )
                                                    }
                                                } if(row.order_status_code == 8 && row.order_status_code !== 11){ 
                                                    if(row.delivery_status == "Delivery"){
                                                        if(row.user_name !== ''){
                                                            if((status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 9 && status.order_status_code !== 10 && status.order_status_code !== 5 && status.order_status_code !== 7 && status.order_status_code !== 11)){
                                                                return (
                                                                    <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                                )
                                                            }
                                                        } else if((status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 9 && status.order_status_code !== 10 && status.order_status_code !== 5 && status.order_status_code !== 7 && status.order_status_code !== 11)){
                                                            return (
                                                                <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                            )
                                                        }
                                                    } else if((status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 3 && status.order_status_code !== 4 && status.order_status_code !== 7 && status.order_status_code !== 9 && status.order_status_code !== 10 && status.order_status_code !== 11)){
                                                        return (
                                                            <option key={index} value={status.order_status_code}>{status.order_status_code == 8 ? "Ready To Pickup" : status.order_status_description}</option>
                                                        )
                                                    }
                                                } if(row.order_status_code == 3 && row.order_status_code !== 11){
                                                    if(row.delivery_status == "Delivery"){
                                                        if((status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 5 && status.order_status_code !== 6 && status.order_status_code !== 7 && status.order_status_code !== 8 && status.order_status_code !== 9 && status.order_status_code !== 10 && status.order_status_code !== 11)){
                                                            return (
                                                                <option key={index} value={status.order_status_code}>{status.order_status_description}</option>
                                                            )
                                                        }
                                                    } else if((status.order_status_code !== 1 && status.order_status_code !== 2 && status.order_status_code !== 4 && status.order_status_code !== 6 && status.order_status_code !== 7 && status.order_status_code !== 8 && status.order_status_code !== 9 && status.order_status_code !== 10 && status.order_status_code !== 11)){
                                                        return (
                                                            <option key={index} value={status.order_status_code}>{status.order_status_code == 3 ? "Ready To Pickup" : status.order_status_description}</option>
                                                        )

                                                    }
                                                } 
                                                return null;
                                            })()} 
                                        </React.Fragment>
                                    );
                            })}
                        </select>
                        <br />
                        {data.buttonDisable == false && <span className="badge badge-pill badge-info text-capitalize" style={{ cursor: 'pointer' }} onClick={() => this.handleSave()} >save</span>}
                    </div>
                }
                <div style={{ display: "none" }}>
                    <button ref={el => this.openModal = el} data-toggle="modal" data-target={`#viewcancel${row.order_id}`} className="btn btn-outline-info ml-2"><i className="fa fa-file-pdf-o"></i></button>
                </div>
                <div id={`viewcancel${row.order_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Cancel Order</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h6>Are you sure,you want to cancel the order?</h6>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <select
                                                name="cancelReason"
                                                onChange={this.handleInputChange}
                                                className="form-control mt-2">
                                                <option value="">Select Reason</option>
                                                {data.cancelReasons &&
                                                    data.cancelReasons.map((reason: any, index: any) => (
                                                        <option key={index} value={reason.id}>{reason.reason}</option>
                                                    ))
                                                }
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input
                                                id="otherReason"
                                                style={{ fontSize: 15 }}
                                                type="text"
                                                name="otherReason"
                                                placeholder="Description"
                                                className="input-material"
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group d-flex justify-content-end mt-4">
                                    <button className='btn btn-primary' disabled={!this.isCancelOrderReady()} onClick={() => this.cancelOrder(row.order_id,row.order_channel,row)}>Save</button>
                                </div>
                            </div>
                            <div style={{ display: "none" }}>
                                <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const divStyle = {
    margin: '0px',
    padding: '15px'
};

class ActionFormatter extends Component<{ row: any }, { selectedOrderId: any, tax: any,brand_id: any, checkTimer: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            selectedOrderId: "",
            tax: "",
            brand_id: "",
            checkTimer: false
        }
    }
    componentDidMount() {
        let tokendata: any = jwt.decode(sessionStorage.token)
        if(tokendata){
            let brandId = tokendata.brand.brand_id;
            this.setState({brand_id: brandId})
        }
    }
    handleTimer = () => {
        if (this.state.checkTimer == true) {
            this.props.data.luanchTimer(this.props.data.history);
        }
    }
    showorderItems = (id: any,channel:any) => {
        this.props.data.stopTimer();
        if (this.props.data.timerStatus == true) {
            this.setState({ checkTimer: true })
        }
        let storeId: any = "";
        let stateId: any = "";
        let tax: any = "";
        this.props.data.showorderItems(id, this.props.data.orders, channel);
        storeId = this.props.data.orders.find((obj: any) => {
            return obj.order_id == id;
        }).store_id;
        if (storeId && storeId !== "") {
            stateId = this.props.data.stores.find((obj: any) => {
                return obj.store_id == storeId;
            }) && this.props.data.stores.find((obj: any) => {
                return obj.store_id == storeId;
            }).state_id;
        }
        if (stateId !== "") {
            tax = this.props.data.states.find((obj: any) => {
                return obj.state_id == stateId;
            }) && this.props.data.states.find((obj: any) => {
                return obj.state_id == stateId;
            }).tax_percent;
        }
        this.setState({ selectedOrderId: id, tax: tax });

    }
    render() {
        const { row, data } = this.props;
        return (
            <div>
                {/* <button title="View Order Items" data-toggle="modal" data-target={`#viewitems${row.order_id}`} className="btn btn-outline-info" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-list"></i></button> */}
                {!row.aggregator_orderId && <button title="View Invoice" className="btn btn-outline-info ml-2" data-toggle="modal" data-target={`#viewinvoice${row.order_id}`} onClick={() => this.showorderItems(row.order_id,row.order_channel)}><i className="fa fa-file-pdf-o"></i></button>}
                {/* {(row.fbr_invoice_number != "") ?
                    <button title="View Invoice" data-toggle="modal" data-target={`#viewinvoice${row.order_id}`} className="btn btn-outline-info ml-2" onClick={() => this.showorderItems(row.order_id)}><i className="fa fa-file-pdf-o"></i></button>
                    : <button title="View Invoice" data-toggle="modal" className="btn btn-outline-danger ml-2"><i className="fa fa-file-o"></i></button>
                } */}
                {/* <!-- Modal--> */}
                <div id={`viewitems${row.order_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Order Items</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="row" style={divStyle}>
                                <div className="col-6 to">
                                    <h3>Customer Information</h3>
                                    <span className="d-block font-weight-light">Customer Name: </span>
                                    <span className="d-block font-weight-light">Phone Number: </span>
                                    <span className="d-block font-weight-light">Email Address: </span>
                                    {data.Items.length > 0 ? data.Items[0].address_line_1 && <span className="d-block font-weight-light">Address 1: </span> : ""}
                                </div>
                                <div className="col-6 to">
                                    <h3><br /></h3>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].first_name} {data.Items.length > 0 && data.Items[0].last_name}</span>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].phone_number}</span>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].email_address}</span>
                                    <span className="d-block font-weight-light">{data.Items.length > 0 && data.Items[0].address_line_1}</span>

                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">

                                            {(data.Items.length > 0) ?

                                                <BootstrapTable data={data.Items} hover>

                                                    <TableHeaderColumn dataField="order_item_id" dataAlign="center" width="80" columnTitle isKey>Order Item Id</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="order_item_quantity" width="100" columnTitle>Quantity</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="item_name" width="150" columnTitle>Item Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="combo_name" width="150" columnTitle>Combo Name</TableHeaderColumn>
                                                    <TableHeaderColumn dataField="order_item_grossprice" width="60" columnTitle>Amount</TableHeaderColumn>

                                                </BootstrapTable> :
                                                <p className="text-center">Order Items Not Found</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Invoice Modal */}
                <div id={`viewinvoice${row.order_id}`} data-backdrop='static' role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog modal-lg" style={{ width: '500px' }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                {/* <h4 id="exampleModalLabel" className="modal-title">Invoice</h4> */}
                                <h4 id="exampleModalLabel" className="modal-title change_color">Invoice</h4>
                                <button type="button" onClick={() => this.handleTimer()} data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                {/* {
                                    row.brand_id == 1 ?
                                        <Invoice data={data} orderId={this.state.selectedOrderId} /> :
                                        <InvoiceAggregator data={data} orderId={this.state.selectedOrderId} />
                                } */}
                                {
                                    getInvoiceComponent(this.state.brand_id, data, this.state.selectedOrderId)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


function priceFormatter(cell: any, row: any) {
    if (row.aggregator_orderId) {
        return `${currency}.${cell}`;
    } else {
        return `${currency}.${Math.round(cell)}`;
    }
}
function nameFormatter(cell: any, row: any) {
    if (row.login_name) {
        // also add check here for the hunger station or other aggregator
        if(row.order_channel == "deliveroo"){
            return `${row.customer_name}`;
        }
        return `${row.login_name}`;
    } else {
        return `${row.customer_name}`;
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}
// seprate invoices function to get the specific invoice
function getInvoiceComponent(brandId:any, data:any, selectedOrderId:any) {
    if (brandId === 1) {
      return <Invoice data={data} orderId={selectedOrderId} />
    } else if (brandId === 3) {
      return <InvoiceAggregator data={data} orderId={selectedOrderId} />
    } else if(brandId == 5) {
      return <HallaInvoice data={data} orderId={selectedOrderId} />
    }
    else {
      return <Invoice data={data} orderId={selectedOrderId} />
    }
}
const selectDriverEditor = (cell: any, row: any, props: any) => {
    return (
        row.delivery_status == "Delivery" && row.order_status_code !== 11 ?
            <DriverEditor row={row} data={props.props} driver_state = {props.state} /> : ""
    )
}
const selectStatusEditor = (cell: any, row: any, props: any) => {
    return (
        <StatusEditor row={row} data={props.props} order_state={props.state} />
    )
}

class Orders extends Component<OrderProps, { orderId: any, days: any, startDate: any, endDate: any, validTimeFlag: any, [x: number]: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            days: 0,
            startDate: "",
            endDate: "",
            validTimeFlag: "",
            orderId: "",
        }
        this.rowClassNameFormat = this.rowClassNameFormat.bind(this);
        this.handleDaysFilter = this.handleDaysFilter.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleValidTime = this.handleValidTime.bind(this)
    }
    componentDidMount() {
        let obj = { days: this.state.days };
        this.props.ordersList(obj);
        this.props.orderStatusList();
        this.props.statesList();
        this.props.cancelOrdersReasons();
        this.props.luanchTimer(this.props.history);
        document.title = "SimplexHDS | Orders"
    }
    rowClassNameFormat(row: any, rowIdx: any) {
        // row is whole row object
        // rowIdx is index of row
        if (row.aggregator_orderId) {
            return 'td-column-function-aggregator-example';
        } else {
            if (row.future_status == 1 && row.order_status_code == 1) {
                return 'td-column-function-odd-example tr-pending-orders'
            } else if (row.future_status == 1) {
                return 'td-column-function-odd-example'
            } else if (row.isNew == true && (row.order_status_code == 1)) {
                return 'td-column-function-even-example tr-pending-orders'
            } else if (row.isNew == true) {
                return 'td-column-function-even-example'
            } else if (row.order_status_code == 1) {
                return 'tr-pending-orders'
            }
           
             else {
                return ''
            }
        }
    }
    handleSearchOrder = (event: any) => {
        event.preventDefault();
        let { orderId } = this.state;
        this.props.searchOrder(orderId)
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        let prevOrdersLength: any = sessionStorage.getItem("prevLength");
        if (nextProps.orders.length > 0) {
            var audio = new Audio();
            audio.src = process.env.PUBLIC_URL + "/assets/sound/old-style-phone-ringing.mp3";
            // if (prevOrdersLength < nextProps.orders.length) {
            nextProps.orders.map((element: any) => {
                if (element.future_status == 1) {
                    if (element.order_status_code == 1 && element.orderStatus == "future") {
                        // audio.play();
                        audio.oncanplaythrough = (event) => {
                            var playedPromise = audio.play();
                            if (playedPromise) {
                                playedPromise.catch((e) => {
                                    console.log(e)
                                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                                        console.log(e.name);
                                    }
                                }).then(() => {
                                    console.log("playing sound !!!");
                                    return
                                });
                            }
                        }
                    } else if ((element.order_status_code == 1 || element.order_status_code == 7) && element.orderStatus == "now") {
                        audio.oncanplaythrough = (event) => {
                            var playedPromise = audio.play();
                            if (playedPromise) {
                                playedPromise.catch((e) => {
                                    console.log(e)
                                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                                        console.log(e.name);
                                    }
                                }).then(() => {
                                    console.log("playing sound !!!");
                                    return
                                });
                            }
                        }
                    }
                } else {
                    if (element.order_status_code == 1) {
                        // audio.play();
                        audio.oncanplaythrough = (event) => {
                            var playedPromise = audio.play();
                            if (playedPromise) {
                                playedPromise.catch((e) => {
                                    console.log(e)
                                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                                        console.log(e.name);
                                    }
                                }).then(() => {
                                    console.log("playing sound !!!");
                                    return
                                });
                            }
                        }

                    }
                }
            });
            // }
        }
    }
    handleDaysFilter(event: { target: { name: any; value: any; }; }) {
        let obj = { days: event.target.value };
        this.props.ordersList(obj);
        // console.log("event.target.value",event.target.value);
    }
    FilterbyStatus(status_code: any) {
        let { orders } = this.props;
        let filteredArr: any = [];
        if (status_code === 4) {
            filteredArr = orders.filter((order: any) => order.order_status_code == status_code || order.order_status_code == 5)
        } else if (status_code === 3) {
            filteredArr = orders.filter((order: any) => order.order_status_code == status_code || order.order_status_code == 8)
        }
        else {
            filteredArr = orders.filter((order: any) => order.order_status_code == status_code)
        }
        this.props.filterOrdersList(filteredArr)
    }
    handleValidTime(event: { target: { name: any; value: any; }; }) {
        let futureDatetime = new Date(event.target.value)
        let validMinDateTime = new Date();
        if (futureDatetime <= validMinDateTime) {
            this.setState({ [event.target.name]: event.target.value, validTimeFlag: true });
        } else {
            this.setState({ validTimeFlag: false })
            toast.error("Invalid date and time selected");
        }
    }
    isOrderDateReady = () => {
        let { startDate, endDate } = this.state;
        return (startDate !== "" && endDate !== "");
    }
    handleInput(event: { target: { name: any; value: any; }; }) {
        this.setState({ orderId: event.target.value })
    };
    handleSubmit() {
        let { startDate, endDate } = this.state;
        let startDateUtc = moment(startDate).utc(false).format('YYYY-MM-DD HH:mm')
        let endDateUtc = moment(endDate).utc(false).format('YYYY-MM-DD HH:mm')
        let data: any = {
            start: startDateUtc,
            end: endDateUtc
        }
        this.props.ordersList(data, true);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }

        let maxDate = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
        let { orders, filteredOrders, emptyFilteredOrder, avg, dayVal, kitchenCount, recievedOrderCount, readyOrderCount, completedOrderCount } = this.props;
        // console.log("Avg time: ", avg);
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: 'Orders Not Found',
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="row py-2">
                                    <div className="col-lg-6 col-md-6 col-12" style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                        <h4 className="mt-2">Orders Management</h4>
                                        <div className="ml-4 ">
                                            {
                                                this.props.timerStatus == true &&
                                                !this.props.updateTimer == true &&
                                                <ReactTimer />
                                            }

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group">
                                                    <input
                                                        id="start"
                                                        type="datetime-local"
                                                        name="startDate"
                                                        max={maxDate}
                                                        value={this.state.startDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input
                                                        id="end"
                                                        type="datetime-local"
                                                        name="endDate"
                                                        max={maxDate}
                                                        value={this.state.endDate}
                                                        className="input-material"
                                                        onChange={this.handleValidTime}
                                                    />
                                                </div>
                                                <button disabled={!this.isOrderDateReady()} className="badge badge-pill btn-info text-capitalize" style={{ cursor: 'pointer', float: 'right' }} onClick={() => { this.handleSubmit() }}>get</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Orders Management</h4>
                                    <select name="days" id="days" onChange={this.handleDaysFilter} style={{ padding: '0 15px' }}>
                                        <option value="0" {...dayVal.days == 0 && { selected: true }}>Today</option>
                                        <option value="1" {...dayVal.days == 1 && { selected: true }}>Yesterday</option>
                                        <option value="7" {...dayVal.days == 7 && { selected: true }}>7 Days</option>
                                        <option value="15" {...dayVal.days == 15 && { selected: true }}>15 Days</option>
                                        <option value="30" {...dayVal.days == 30 && { selected: true }}>30 Days</option>
                                        <option value="60" {...dayVal.days == 60 && { selected: true }}>60 Days</option>
                                        <option value="90" {...dayVal.days == 90 && { selected: true }}>90 Days</option>
                                    </select>
                                </div> */}
                            </div>
                        </header>
                        {/* Counters Section */}
                        <section className="dashboard-counts py-2 px-2">
                            <div className="container-fluid">
                                <div className="row bg-white has-shadow p-0">
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-2 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(1) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-green"><i className="fa fa-money"></i></div>
                                            <div className="title"><span>Pending</span><br />
                                                <strong className="text-bold text-dark">{recievedOrderCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${recievedOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-green"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{recievedOrderCount}</strong></div> */}
                                        </div>
                                    </div>

                                    {/* <!-- Item --> */}
                                    <div className="col-xl-2 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(2) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-blue"><i className="fa fa-cutlery"></i></div>
                                            <div className="title"><span>Kitchen</span> <br />
                                                <strong className="text-bold text-dark">{kitchenCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${kitchenCount / 100}%`, height: "4px" }} className="progress-bar bg-blue"></div>  {/*ariaValuenow="25" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{kitchenCount}</strong></div> */}
                                        </div>
                                    </div>
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-2 col-sm-6">
                                        <div onClick={() => { this.FilterbyStatus(3) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-orange"><i className="fa fa-truck"></i></div>
                                            <div className="title"><span>Ready</span><br />
                                                <strong className="text-bold text-dark">{readyOrderCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${readyOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-orange"></div>  {/*ariaValuenow="40" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div className="number"><strong>{readyOrderCount}</strong></div> */}
                                        </div>
                                    </div>
                                    {/* <!-- Item --> */}
                                    <div className="col-xl-3 col-sm-6" style={{ maxWidth: "23%" }}>
                                        <div onClick={() => { this.FilterbyStatus(4) }} className="item d-flex align-items-center" style={{ border: "none", cursor: 'pointer' }}>
                                            <div className="icon bg-violet"><i className="fa fa-handshake-o"></i></div>
                                            <div className="title"><span>Completed</span> <br />
                                                <strong className="text-bold text-dark">{completedOrderCount}</strong>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${completedOrderCount / 100}%`, height: "4px" }} className="progress-bar bg-violet"></div> {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div><strong>{completedOrderCount}</strong></div> */}
                                        </div>
                                    </div>
                                    {/* Item */}
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="item d-flex align-items-center" style={{ border: "none" }}>
                                            <div className="icon bg-red"><i className="fa fa-clock-o"></i></div>
                                            <div className="title"><span>Avg Delivery<br />Time <strong className="text-bold text-dark">{avg}</strong></span>
                                                <div className="progress">
                                                    <div role="progressbar" style={{ width: `${avg / 100}%`, height: "4px" }} className="progress-bar bg-red"></div> {/*ariaValuenow="50" ariaValuemin="0" ariaValuemax="100"*/}
                                                </div>
                                            </div>
                                            {/* <div><strong>{avg}</strong></div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* End Counters Section */}

                        <section className="tables py-2">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-10 col-md-8 col-12">
                                                        <div className="form-group">
                                                            <input id="orderId" type="number" value={this.state.orderId} name="orderId" required placeholder="Search by order id" className="input-material" onChange={this.handleInput} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-12 float-right mt-1">
                                                        <div className="form-group">
                                                            <button onClick={this.handleSearchOrder} disabled={this.state.orderId == ""} className="btn btn-primary btn-block">Search Order</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                { this.props.applyLoaderonDate ?
                                                    <div className="d-flex justify-content-center">
                                                        <Loader
                                                            //style={{}}
                                                            type="TailSpin"
                                                            color="#007bff"
                                                            height={50}
                                                            width={50}
                                                        />
                                                    </div> :
                                                (<BootstrapTable version='4' data={filteredOrders.length > 0 || emptyFilteredOrder ? filteredOrders : orders} trClassName={this.rowClassNameFormat} search={true} pagination={orders.length > 10 && true} options={options}>
                                                        <TableHeaderColumn dataField='order_id' thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} csvHeader='Order Id' dataSort={true} isKey>Order Id</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='action' thStyle={{ fontSize: 10, fontWeight: 'bold' }} width='80' tdStyle={{ fontSize: 10 }} dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Details</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='date_created' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Order Recieved' dataFormat={dateFormatter} columnTitle>Order Recieved</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='store_name' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Store' columnTitle>Store</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='delivery_status' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='90' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Order Type' columnTitle>Order Type</TableHeaderColumn>
                                                        <TableHeaderColumn  dataFormat={channelFormatter} thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='110' tdStyle={{ fontSize: 10, whiteSpace: 'normal', textTransform: 'capitalize' }} columnTitle>Order Channel</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='external_orderId' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='90' tdStyle={{ fontSize: 10, whiteSpace: 'normal', textTransform: 'capitalize' }} columnTitle>Agg OrderID</TableHeaderColumn>
                                                        {/* <TableHeaderColumn dataField='delivery_time_json' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='90' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={calculateDeliveryTime} csvHeader='Delivery time' export={false}>Delivery Time</TableHeaderColumn> */}
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='delivery_time' dataFormat={calculateDeliveryTime} csvHeader='Delivery time'>Delivery Time</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10 }} dataField='order_status_description' dataFormat={selectStatusEditor} formatExtraData={{props:this.props,state:this.state}} >Status</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='180' tdStyle={{ fontSize: 10 }} dataField='user_name' dataFormat={selectDriverEditor} formatExtraData={{props:this.props,state:this.state}} csvHeader='Delivery Driver'>Delivery Driver</TableHeaderColumn>
                                                        {/* <TableHeaderColumn dataField='customer_name' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Customer Name' columnTitle>Name</TableHeaderColumn> */}
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='customer_name' dataFormat={nameFormatter} csvHeader='Customer Name' columnTitle>Name</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='phone_number' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Phone' columnTitle>Phone</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='delivery_address' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='150' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Address' columnTitle>Address</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='order_grossprice' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={priceFormatter} csvHeader='Price'>Order Price</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='discount' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataFormat={priceFormatter} csvHeader='Discount'>Discount</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='payment_method' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='80' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Payment Method' columnTitle>Payment Method</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='reason' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Cancel Reason' columnTitle>Cancel Reason</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='120' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='cancel_reason_description' csvHeader='Cancel Reason Description' columnTitle>Cancel Reason Description</TableHeaderColumn>
                                                        {/* <TableHeaderColumn dataField='feedback' csvHeader='Feedback' width='150' columnTitle>Feedback</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='ratings' width='200' csvHeader='Rating' dataFormat={ratingsFormatter} columnTitle>Rating</TableHeaderColumn> */}
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='fbr_invoice_number' csvHeader='FBR Invoice Number' columnTitle>FBR Invoice Number</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='check_number' thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} csvHeader='Check Number' columnTitle>Check Number</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'normal' }} width='100' tdStyle={{ fontSize: 10, whiteSpace: 'normal' }} dataField='aggregator_orderId' csvHeader='AggregatorOrderId' columnTitle>Aggregator OrderId</TableHeaderColumn>
                                                    </BootstrapTable>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
};
function channelFormatter(cell: any, row: any) {
    if (row.order_channel == 'deliveroo') {
        return `${row.order_channel} (${row.external_orderId})`;
    } else {
        return `${row.order_channel}`;
    }
}
function dateFormatter(cell: any) {
    var DateCreated: any = moment(cell).local().format('YYYY-MM-DD HH:mm').split(' ');
    DateCreated = DateCreated[1] + " , " + DateCreated[0];
    return (
        <div>
            <span {...((cell !== "" && cell !== null) && { title: DateCreated })}> {(cell !== "" && cell !== null) && DateCreated} </span>
        </div>
    )
}
function calculateDeliveryTime(cell: any, row: any) {
    // let DeliveryTime: any = row.delivery_time_json && JSON.parse(row.delivery_time_json);
    // let ActualDeliveryTime = row.delivery_time_json ? DeliveryTime.pending + DeliveryTime.kitchen + DeliveryTime.ready + DeliveryTime.dispatched : 0
    return (
        <div>
            <span {...((row.order_status_code == 4 && cell > 0) && { title: `${cell} min` })}> {(row.order_status_code == 4 && cell > 0) && `${cell} min`} </span>
        </div>
    )
}
function ratingsFormatter(cell: any, row: any) {
    return (
        <BeautyStars
            value={cell}
            size="16"
            inactiveColor="#b7b7b7"
        />
    )
}
const mapStateToProps = (state: any) => {
    return {
        orders: state.order.data,
        filteredOrders: state.order.filteredOrders,
        emptyFilteredOrder: state.order.emptyFilterOrder,
        Items: state.order.orderItems,
        orderDetail: state.order.order,
        statusList: state.order.orderStatus,
        drivers: state.order.deliveryBoys,
        states: state.order.states,
        cancelReasons: state.order.cancelReasons,
        stores: state.order.stores,
        prevOrdersLength: state.order.prevOrdersLength,
        buttonDisable: state.order.buttonDisable,
        kitchenCount: state.order.kitchenCount,
        recievedOrderCount: state.order.recievedOrderCount,
        readyOrderCount: state.order.readyOrderCount,
        completedOrderCount: state.order.completedOrderCount,
        dayVal: state.order.dayVal,
        avg: state.order.avg,
        applyLoaderonDate: state.order.isLoading,
        updateTimer: state.order.isTimerUpdate,
        timerStatus: state.order.timerStatus,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        ordersList: function (obj: any, datetime: any) {
            dispatch(ordersList(obj, datetime))
        },
        showorderItems: function (id: any, orders: any, channel:any) {
            dispatch(showorderItems(id, orders, channel))
        },
        cancelOrdersReasons: () => {
            dispatch(cancelOrdersReasons())
        },
        orderStatusList: function () {
            dispatch(orderStatusList())
        },
        updateOrder: (id: any, data: any, history: any) => {
            dispatch(updateOrder(id, data, history))
        },
        updatefutureOrder: (id: any, data: any, history: any) => {
            dispatch(updatefutureOrder(id, data, history))
        },
        statesList: function () {
            dispatch(statesList())
        },
        luanchTimer: (history: any) => {
            dispatch(luanchTimer(history))
        },
        stopTimer: () => {
            dispatch(stopTimer())
        },
        filterOrdersList: (filteredArr: any) => {
            dispatch(filterOrdersList(filteredArr))
        },
        searchOrder: (orderId: any) => {
            dispatch(searchOrder(orderId))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Orders);