import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { getAllMenuList, logoutUser, searchMenuItem, statusChangeReasons, updateItemStatus } from '../../redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Toggle from 'react-toggle'
import 'react-tabs/style/react-tabs.css';
import "react-toggle/style.css"
import './menuStyle.css'
import { MenuProps, MenuState } from '../../interfaces/menu';

class Menu extends Component<MenuProps, MenuState> {
    openModal: any;
    closeModal: any;
    constructor(readonly props: any) {
        super(props);
        this.state = {
            activeTab: 0,
            status: false,
            is_active: "",
            data: {},
            statusReason: ""
        }
        this.handleToggleChange = this.handleToggleChange.bind(this);
        this.saveLogsForToggle = this.saveLogsForToggle.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.getAllMenuList();
        this.props.statusChange();
        document.title = "SimplexHDS | Menu"
    }

    handleToggleChange(e: any, data: any) {
        this.openModal.click();
        this.setState({
            data: data,
            is_active: e.target.checked == false ? 0 : 1
        })
    }
    saveLogsForToggle() {
        let { data, is_active, statusReason } = this.state;
        let body: any = {
            is_active: is_active,
            reason: statusReason,
            status: is_active == 1 ? "UnBlock" : "Block"
        }
        if (data.group_id) {
            body.group_id = data.group_id.group_id
        } else if (data.menu_item_id) {
            body.menu_item_id = data.menu_item_id.menu_item_id
        } else if (data.combo_id) {
            body.combo_id = data.combo_id.combo_id
        }
        this.props.updateItemStatus(body, this.props.history);
        this.closeModal.click();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == "menu_item") {
            this.props.searchMenuItem(event.target.value, this.props.itemsData)
        } else if (event.target.name == "group") {
            this.props.searchGroup(event.target.value, this.props.groupsData)
        } else if (event.target.name == "combo") {
            this.props.searchCombo(event.target.value, this.props.combosData)
        }
    }
    changeTab = (index: any) => {
        this.setState({
            activeTab: index
        })
        this.props.getAllMenuList()
    }
    isSaveStatusReady = () => {
        const { statusReason, is_active } = this.state;
        if (is_active == 0) {
            return (statusReason !== "");
        } else {
            return true;
        }
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let { searchItemData, itemStatusReasons } = this.props;
        let { is_active } = this.state;
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Menu Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">

                                            <div className="card-body p-0 mb-5">
                                                <Tabs
                                                    selectedIndex={this.state.activeTab}
                                                    onSelect={(index) =>
                                                        this.changeTab(index)
                                                    }
                                                >
                                                    <TabList
                                                        style={{
                                                            background: "#d8b165",
                                                            color: "#FFF",
                                                            padding: "10px",
                                                        }}
                                                    >
                                                        {/* <Tab>Groups</Tab> */}
                                                        <Tab>Menu Items</Tab>
                                                        {/* <Tab>Combos</Tab> */}
                                                    </TabList>

                                                    {/* <TabPanel style={{ marginTop: '2em' }}>
                                                        <div className="container-fluid">

                                                            <div className="row">
                                                                <div className="col-lg-12 col-12">
                                                                    <div className="card-header">
                                                                        <strong>All Groups</strong>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-body text-capitalize">
                                                                            <div className="row">
                                                                                {groupsData.length > 0 ? groupsData.map((element: any, index: any) => {
                                                                                    return (
                                                                                        <div className="col-lg-6 my-2">
                                                                                            <div className="d-flex justify-content-between">
                                                                                                <div>{element.group_id.group_name}</div>
                                                                                                <div>
                                                                                                    <Toggle
                                                                                                        className='custom-classname'
                                                                                                        defaultChecked={element.is_active == 0 ? false : true}
                                                                                                        icons={false}
                                                                                                        onChange={(e: any) => this.handleToggleChange(e, element)} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }) :
                                                                                    <div className="col-12 text-center">
                                                                                        <h5>Groups not found</h5>
                                                                                    </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </TabPanel> */}
                                                    <TabPanel style={{ marginTop: '2em' }}>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-12">
                                                                    <div className="card-header">
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <strong>All Items</strong>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="search-box">
                                                                                    <label className="mb-0">
                                                                                        <i className="fa fa-search"></i>
                                                                                    </label>
                                                                                    <input id="menu_item" type="text" name="menu_item" style={{ border: 'none', marginLeft: '10px', width: '100%' }} required data-msg="Please enter item" onChange={this.handleInputChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                {searchItemData.length > 0 ? searchItemData.map((element: any, index: any) => {
                                                                                    return (
                                                                                        <div className="col-lg-6 my-2">
                                                                                            <div className="d-flex justify-content-between">
                                                                                                <div>{element.menu_item_id.item_name}</div>
                                                                                                <div>
                                                                                                    <Toggle
                                                                                                        className='custom-classname'
                                                                                                        checked={element.is_active == 0 ? false : true}
                                                                                                        icons={false}
                                                                                                        onChange={(e: any) => this.handleToggleChange(e, element)} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }) :
                                                                                    <div className="col-12 text-center">
                                                                                        <h5>Menu Items not found</h5>
                                                                                    </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </TabPanel>
                                                    {/* <TabPanel style={{ marginTop: '2em' }}>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-12">
                                                                    <div className="card-header">
                                                                        <strong>All Combos</strong>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                {combosData.length > 0 ? combosData.map((element: any, index: any) => {
                                                                                    return (
                                                                                        <div className="col-lg-6 my-2">
                                                                                            <div className="d-flex justify-content-between">
                                                                                                <div>{element.combo_id.combo_name}</div>
                                                                                                <div>
                                                                                                    <Toggle
                                                                                                        className='custom-classname'
                                                                                                        defaultChecked={element.is_active == 0 ? false : true}
                                                                                                        icons={false}
                                                                                                        onChange={(e: any) => this.handleToggleChange(e, element)} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }) :
                                                                                    <div className="col-12 text-center">
                                                                                        <h5 >Combos not found</h5>
                                                                                    </div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabPanel> */}
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
                {/* End Print Preview */}
                <div style={{ display: "none" }}>
                    <button ref={el => this.openModal = el} data-toggle="modal" data-target={`#saveLogs`} className="btn btn-outline-info ml-2"><i className="fa fa-file-pdf-o"></i></button>
                </div>
                <div id={`saveLogs`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{is_active==1 ? "Unblock" : "Block"} Item</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <h6>Are you sure,you want to {is_active==1 ? "Unblock" : "Block"} the Item?</h6>
                                    </div>
                                    {is_active == 0 &&
                                        <div className="col-12">
                                            <div className="form-group">
                                                <select
                                                    name="statusReason"
                                                    onChange={this.handleInputChange}
                                                    className="form-control mt-2">
                                                    <option value="">Select Reason</option>
                                                    {itemStatusReasons &&
                                                        itemStatusReasons.map((reason: any, index: any) => (
                                                            <option key={index} value={reason.id}>{reason.reason}</option>
                                                        ))
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="form-group d-flex justify-content-end mt-4">
                                    <button className='btn btn-primary' disabled={!this.isSaveStatusReady()} onClick={() => this.saveLogsForToggle()}>Save</button>
                                    {/*  */}
                                </div>
                            </div>
                            <div style={{ display: "none" }}>
                                <button ref={el => this.closeModal = el} type="button" data-dismiss="modal" className="btn btn-info" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        groupsData: state.menu.groupsData,
        itemsData: state.menu.itemsData,
        combosData: state.menu.combosData,
        searchItemData: state.menu.searchItemData,
        itemStatusReasons: state.menu.statusChangeReasons
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        getAllMenuList: () => {
            dispatch(getAllMenuList());
        },
        updateItemStatus: (data: any, history: any) => {
            dispatch(updateItemStatus(data, history))
        },
        statusChange: () => {
            dispatch(statusChangeReasons())
        },
        searchMenuItem: (value: any, data: any) => {
            dispatch(searchMenuItem(value, data))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu);