import { GET_MENU, SEARCH_MENU_ITEM, STATUS_CHANGE_REASONS } from '../actions/menuTypes';
const initialState: any = {
    groupsData: [] || "",
    itemsData: [] || "",
    combosData: [] || "",
    searchItemData:[]||"",
    statusChangeReasons: [] || ""
}

const menuReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_MENU: return {
            ...state,
            groupsData: action.groupsData,
            itemsData: action.itemsData,
            combosData: action.combosData
        }
        case STATUS_CHANGE_REASONS: return {
            ...state,
            statusChangeReasons: action.payload
        }
        case SEARCH_MENU_ITEM: return {
            ...state,
            searchItemData: action.searchItemData
        }
        default: return state;
    }
}
export default menuReducer;