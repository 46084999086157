import { USERS_LIST, ADD_USER, EDIT_USER, GET_USER, ROLES_LIST, ADD_ROLE, EDIT_ROLE, GET_ROLE, SAVE_TAX, GET_TAX, TAX_LIST,DISCOUNT_LIST, SAVE_DISCOUNT, SAVE_DELIVERY,STATES_LIST, ADD_STATE } from '../actions/settingsType'
const initialState: any = {
    users: [] || "",
    roles: [] || "",
    tax: [] || "",
    discounts: [] || "",
    states:[]||"",
    userData: {},
    roleData: {},
    taxData: {},
    state_id:"",
    message: "",
    discMsg: "",
    deliveryMsg:"",
    saveDiscount: "",
    isUpdated: "",
    saveDelivery:"",
    isSaved: ""
}

const settingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case USERS_LIST: return {
            ...state,
            users: action.payload
        }
        case ADD_USER: return {
            ...state,
            message: action.payload,
            isInserted: action.isInserted,
        }
        case EDIT_USER: return {
            ...state,
            message: action.payload,
            isUpdated: action.isUpdated
        }
        case GET_USER: return {
            ...state,
            userData: action.payload
        }
        case ROLES_LIST: return {
            ...state,
            roles: action.payload
        }
        case ADD_ROLE: return {
            ...state,
            message: action.payload,
            isInserted: action.isInserted,
        }
        case EDIT_ROLE: return {
            ...state,
            message: action.payload,
            isUpdated: action.isUpdated
        }
        case GET_ROLE: return {
            ...state,
            roleData: action.payload
        }
        case SAVE_TAX: return {
            ...state,
            message: action.payload,
            isSaved: action.isSaved
        }
        case GET_TAX: return {
            ...state,
            taxData: action.payload
        }
        case TAX_LIST: return {
            ...state,
            tax: action.payload
        }
        case DISCOUNT_LIST:return {
            ...state,
            discounts: action.payload
        }
        case SAVE_DISCOUNT: return {
            ...state,
            discMsg: action.payload,
            saveDiscount: action.saveDiscount
        }
        case SAVE_DELIVERY: return {
            ...state,
            deliveryMsg: action.payload,
            saveDelivery: action.saveDelivery
        }
        case STATES_LIST:return {
            ...state,
            states: action.payload
        }
        case ADD_STATE: return {
            ...state,
            message: action.payload,
            isInserted: action.isInserted,
            state_id:action.state_id
        }
        default: return state;
    }
}
export default settingReducer;