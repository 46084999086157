export const USERS_LIST = "USERS_LIST"
export const ADD_USER = "ADD_USER"
export const EDIT_USER = "EDIT_USER"
export const GET_USER = "GET_USER"
export const ROLES_LIST = "ROLES_LIST"
export const ADD_ROLE = "ADD_ROLE"
export const EDIT_ROLE = "EDIT_ROLE"
export const GET_ROLE = "GET_ROLE"
export const SAVE_TAX = "SAVE_TAX"
export const GET_TAX = "GET_TAX"
export const TAX_LIST = "TAX_LIST"
export const DISCOUNT_LIST="DISCOUNT_LIST"
export const SAVE_DISCOUNT = "SAVE_DISCOUNT"
export const SAVE_DELIVERY = "SAVE_DELIVERY"
export const STATES_LIST="STATES_LIST"
export const ADD_STATE="ADD_STATE"