import { createStore, applyMiddleware, combineReducers } from 'redux'
import menuReducer from './reducers/menuReducer'
import orderReducer from './reducers/orderReducer'
import homeReducer from './reducers/homeReducer'
import reportReducer from './reducers/reportReducer';
import settingReducer from './reducers/settingReducer';
import driverReducer from './reducers/drivers';
import userReducer from './reducers/userReducer';
const thunkMiddleware = require('redux-thunk').default;

const mainReducer = combineReducers({
    home: homeReducer,
    user: userReducer,
    menu: menuReducer,
    order: orderReducer,
    report: reportReducer,
    setting: settingReducer,
    driver: driverReducer
})
const store = createStore(mainReducer, applyMiddleware(thunkMiddleware))

export default store;