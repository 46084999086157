import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import Login from './pages/login';
import Forgot from './pages/forgotpass';
import Home from './pages/home';
import Profile from './pages/profile';
import Orders from './pages/orders/orders';
import UnresolvedOrders from './pages/orders/unresolvedorders';
import Reports from './pages/reports/reports'
import Drivers from './pages/drivers/drivers';
import addDriver from './pages/drivers/add-driver';
import editDriver from './pages/drivers/edit-driver';
import CustomerFeedback from './pages/feedback/feedback';
import menu from './pages/menu/menu';
import jwt from 'jsonwebtoken'
const Notfound = () => <h1 className="text-center">Not found</h1>
function App() {
  let tokendata: any = jwt.decode(sessionStorage.token)
  let features: any = {};
  if (sessionStorage.token) {
    features = tokendata.features ? JSON.parse(tokendata.features) : tokendata.features;
  }
  return (
    // forceRefresh is used to force React Router to reload the page on every navigation
    <div className="App">
      < Provider store={store} >
        <Router forceRefresh>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/forgot" component={Forgot} />
            {(features && features.feature15 && features.feature15.permission == 1) && <Route exact path="/dashboard" component={Home} />}
            <Route exact path="/profile" component={Profile} />
            {(features && features.feature14 && features.feature14.permission == 1) && <Route exact path="/orders" component={Orders} />}
            {(features && features.feature14 && features.feature14.permission == 1) && <Route exact path="/unresolved-orders" component={UnresolvedOrders} />}
            {(features && features.feature19 && features.feature19.permission == 1) && <Route exact path="/analytics" component={Reports} />}
            {(features && features.feature17 && features.feature17.permission == 1) && <Route exact path="/drivers" component={Drivers} />}
            {(features && features.feature17 && features.feature17.permission == 1) && <Route rxact path="/add-driver" component={addDriver} />}
            {(features && features.feature17 && features.feature17.permission == 1) && <Route rxact path="/edit-driver/:id" component={editDriver} />}
            {(features && features.feature18 && features.feature18.permission == 1) && <Route exact path="/feedback" component={CustomerFeedback} />}
            {(features && features.feature16 && features.feature16.permission == 1) && <Route exact path="/menu" component={menu} />}
            {/*<Route exact path="/add-user" component={AddUser} />
            <Route exact path="/edit-user/:id" component={EditUser} /> */}
            <Route component={Notfound} />
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
