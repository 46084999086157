import { DRIVER_LIST, ADD_DRIVER, EDIT_DRIVER, GET_DRIVER, COUNTRY_LIST } from '../actions/driverTypes'
const initialState: any = {
    drivers: [] || "",
    driverData: {},
    countries:[]||"",
    state_id:"",
    message: "",
    isUpdated: "",
    isSaved: ""
}

const driverReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case DRIVER_LIST: return {
            ...state,
            drivers: action.payload
        }
        case ADD_DRIVER: return {
            ...state,
            message: action.payload,
            isInserted: action.isInserted,
        }
        case EDIT_DRIVER: return {
            ...state,
            message: action.payload,
            isUpdated: action.isUpdated
        }
        case GET_DRIVER: return {
            ...state,
            driverData: action.payload
        }
        case COUNTRY_LIST:return {
            ...state,
            countries:action.payload
        }
        default: return state;
    }
}
export default driverReducer;