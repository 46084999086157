import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import BeautyStars from "beauty-stars";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import { logoutUser, sendFeedback } from "../../redux";
import { FeedBackProps } from "../../interfaces/reports";
import { feedbackList } from "../../redux/actions/reportAction";
import { activeInactiveStatus } from './../../redux/actions/reportAction';

class ActionFormatter extends Component<{ row: any, data: any }, { response: any, [x: number]: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            response: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    // handleDelete = (row: any) => {
    //     console.log("row", row)

    //     this.props.deleteFeedback(row.id);
    // };
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleActiveInActive = (id: any, status: any) => {
        this.props.data.activeInactiveStatus(id, status, this.props.data.history);
    };
    handleSubmit = (e: any) => {
        e.preventDefault();
        const { response } = this.state;
        //let id = this.props.match.params.id;
        const { row, data } = this.props;
        let resp: any = { store_response: response }
        this.props.data.sendFeedback(row.id, resp, this.props.data.history, row.receipt_number);
    }

    render() {
        const { row } = this.props;
        const dateOrder = new Date(row.order_date).toLocaleDateString();
        return (
            <div>
                <button
                    title="View Feedback"
                    className="btn btn-outline-primary mr-2"
                    data-toggle="modal"
                    data-target={`#ViewFeedback${row.id}`}
                >
                    <i className="fa fa-eye"></i>
                </button>
                <button
                    title="Send Feedback"
                    className="btn btn-outline-primary mr-2"
                    data-toggle="modal"
                    data-target={`#SendFeedback${row.id}`}
                //onClick={this.handleSubmit}
                >
                    <i className="fa fa-comments"></i>
                </button>
                {row.status == 0 &&
                    <button title={row.status === 0 ? "Resolved" : ""} data-toggle="modal" data-target={`#activeInactive${row.id}`} className={row.status === 0 ? "btn btn-outline-danger mr-2" : "btn btn-outline-success mr-2"}><i className={row.status === 0 ? "fa fa-check" : "fa fa-unlock"}></i></button>}
                {/* Send Model */}
                <div
                    id={`SendFeedback${row.id}`}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    className="modal fade text-left"
                >
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">
                                    Feedback Resolution
                                </h4>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h3>FeedBack</h3>
                                <p>{row.message}</p>
                                <hr />
                                <h3>Resolution</h3>
                                <div>
                                    <textarea name="response" id="round2" placeholder="Write your resolution here." onChange={this.handleInputChange} style={{ width: '100%', height: '100px' }}></textarea>
                                </div>
                                <button className="btn btn-primary" onClick={this.handleSubmit}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Active In Active */}
                <div id={`activeInactive${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Issue Resolved</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure issue has been resolved?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleActiveInActive(row.id, row.status)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--View Modal--> */}
                <div
                    id={`ViewFeedback${row.id}`}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    className="modal fade text-left"
                >
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">
                                    Customer Feedback
                                </h4>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-row" >
                                    {/* <div className="form-group col-md-12"> <br /></div>
                                            <div className="form-group col-md-12"><h4 id="txt">Feedback Form</h4></div> */}
                                    <div className="form-group col-md-6">
                                        <h5 id="txt">Name</h5>
                                        <input name="fullname" disabled defaultValue={row.name} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <h5 id="txt">Phone</h5>
                                        <input name="phone" disabled defaultValue={row.phone_number} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" required />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <h5 id="txt">Email</h5>
                                        <input name="email" disabled defaultValue={row.email_address} style={{ fontSize: "15px" }} type="email" className="form-control bg-white" id="round" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <h5 id="txt">Store</h5>
                                        <input type="text" name="store_name" disabled defaultValue={row.store_id.store_name} required className="form-control bg-white" id="round" />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <h5 id="txt">Order Date</h5>
                                        <input type="text" name="dateOfOrder" disabled defaultValue={dateOrder} required data-msg="Please Enter Order Date" className="form-control bg-white" id="round" />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <h5 id="txt"> Receipt Number</h5>
                                        <input name="receiptnumber" disabled defaultValue={row.receipt_number} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <h5 id="txt"> Feedback Type</h5>
                                        <input name="receiptnumber" disabled defaultValue={row.type} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <h5 id="txt">Feedback</h5>
                                        <textarea name="feedbackMessage" id="round2" disabled style={{ width: '100%', height: '100px' }}>
                                            {row.message}
                                        </textarea>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    className="btn btn-danger"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function statusFormatter(cell: any) {
    return (
        <div className="text-capitalize">
            <span {...(cell == 0 && { className: "badge badge-info p-2" }) || (cell == 1 && { className: "badge badge-success p-2" })}>{cell == 0 ? "Pending" : "Resolved"}</span>
        </div>
    )
}
function actionFormatter(cell: any, row: any, props: any) {
    return <ActionFormatter row={row} data={props} />;
}
function feedbackFormatter(cell: any, row: any, props: any) {
    return <FeedbackFormatter row={row} />;
}
class FeedbackFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);

    }
    // handleDelete = (row: any) => {
    //   console.log("row",row)

    //   // this.props.delete(id);
    // };
    render() {
        const { row } = this.props;
        const dateOrder = new Date(row.order_date).toLocaleDateString();
        // const dateOrder = row.order_date

        return (
            <div>
                {row.message.length > 30 ?
                    <button

                        title="View Feedback"
                        className="btn btn-outline-primary"
                        data-toggle="modal"
                        data-target={`#ViewFeedbacks${row.id}`}
                    // onClick={() => this.handleDelete(row)}
                    >
                        <span style={{ fontSize: "12px" }} >view feedback</span>
                    </button>
                    :
                    <>
                        {row && row.message}
                    </>
                }

                {/* <!-- Modal--> */}
                <div
                    id={`ViewFeedbacks${row.id}`}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    className="modal fade text-left"
                >
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">
                                    Customer Feedback
                                </h4>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-row" >
                                    <div className="form-group col-md-12">
                                        <h5 id="txt">Feedback</h5>
                                        <textarea name="feedbackMessage" id="round2" disabled style={{ width: '100%', height: '100px' }}>
                                            {row.message}
                                        </textarea>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    className="btn btn-danger"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class CustomerFeedback extends Component<FeedBackProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.feedbackList();
        document.title = "SimplexHDS | Customer Feedback";
    }
    render() {
        const { data } = this.props
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />;
        }
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: "Customer Feedbacks Not Found"
        };

        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Customer Complaints Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body text-capitalize">

                                                <div className="card-body">
                                                    <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} hover>
                                                        {/* <TableHeaderColumn dataField='customer_id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn> */}
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='name' csvHeader='Name' width='130' columnTitle dataSort={true} isKey>Name</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='email_address' csvHeader='Email' width='130' columnTitle>Email</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='phone_number' csvHeader='Phone' width='120' columnTitle>Phone</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='type' csvHeader='Type' width='120' columnTitle>Type</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='receipt_number' csvHeader='Receipt Number' width='100' columnTitle>Receipt Number</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='date_created'  csvHeader='Complaint Date' width='130' columnTitle>Complaint Date</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='resolved_date'   csvHeader='Resolve Date' width='130' columnTitle>Resolve Date</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='store_response' csvHeader='Response' width='150' columnTitle>Resolution</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='message' dataFormat={feedbackFormatter} csvHeader='Feedback' width='130' columnTitle>Feedback</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='status' csvHeader='Status' width='100' dataFormat={statusFormatter} columnTitle export={false}>Status</TableHeaderColumn>
                                                        <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='200' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>

                                                    </BootstrapTable>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        data: state.report.feedbackData
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        feedbackList: function () {
            dispatch(feedbackList());
        },
        sendFeedback: function (f_id: any, res: any, history: any, receipt_number: any) {
            dispatch(sendFeedback(f_id, res, history, receipt_number));
        },
        activeInactiveStatus: function (id: any, status: any, history: any) {
            dispatch(activeInactiveStatus(id, status, history));
        },


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerFeedback);
