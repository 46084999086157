import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { connect } from 'react-redux';
import { storesList } from '../redux/actions/orderAction';
class Sidebar extends Component<{ storesList: () => {}, loginStore: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.storesList()
    }
    render() {
        let { loginStore } = this.props;
        let page = window.location.pathname.split('/');
        let name = sessionStorage.name;
        let tokendata: any = jwt.decode(sessionStorage.token)
        let features: any = {};
        if (sessionStorage.token) {
            features = tokendata.features ? JSON.parse(tokendata.features) : tokendata.features;
        }
        return (
            // <!-- Side Navbar -->
            <nav className="side-navbar">
                {/* <!-- Sidebar Header--> */}
                <div className="sidebar-header d-flex align-items-center">
                    <div className="avatar" style={{ borderRadius: 30, marginBottom: 7 }}>
                        <img src={process.env.PUBLIC_URL + "/assets/img/main-logo.png"} alt="..." className="img-fluid rounded-circle" />
                    </div>
                    <div className="title">
                        <h1 className="h4">{name}</h1>
                    </div>
                </div>
                {/* <!-- Sidebar Navidation Menus--> */}

                <ul className="list-unstyled">

                    {(features && features.feature14 && features.feature14.permission == 1) && <li className={page[1] === 'orders' ? 'active' : ''}><Link to="/orders"> <i className="fa fa-shopping-cart"></i>Orders </Link></li>}
                    {(features && features.feature14 && features.feature14.permission == 1 && loginStore.is_suspend == 1) && <li className={page[1] === 'unresolved-orders' ? 'active' : ''}><Link to="/unresolved-orders"> <i className="fa fa-shopping-cart"></i>Unresolved Orders </Link></li>}
                    {(features && features.feature15 && features.feature15.permission == 1) && <li className={page[1] === 'dashboard' ? 'active' : ''}><Link to="/dashboard"> <i className="fa fa-home"></i>Dashboard</Link></li>}
                    {(features && features.feature16 && features.feature16.permission == 1) && <li className={page[1] === 'menu' ? 'active' : ''}><Link to="/menu"> <i className="fa fa-bars"></i>Branch Menu</Link></li>}
                    {(features && features.feature17 && features.feature17.permission == 1) && <li className={page[1] === 'drivers' ? 'active' : ''}><Link to="/drivers"> <i className="fa fa-truck"></i>Drivers</Link></li>}
                    {(features && features.feature18 && features.feature18.permission == 1) && <li className={page[1] === 'feedback' ? 'active' : ''}><Link to="/feedback"> <i className="fa fa-comments-o"></i>Feedback</Link></li>}
                    {/* {(features && features.feature19 && features.feature19.permission == 1) && <li className={page[1] === 'analytics' ? 'active' : ''}><Link to="/analytics"> <i className="fa fa-question-circle"></i>Analytics</Link></li>} */}
                    {(features && features.feature19 && features.feature19.permission == 1) && <li className={page[1] === 'analytics' ? 'active' : ''}><Link to="/analytics"> <i className="fa fa-question-circle"></i>Analytics</Link></li>}

                    {/* <li><Link to="#"> <i className="fa fa-question-circle"></i>Faq</Link></li>
                    <li><Link to="#"> <i className="fa fa-support"></i>Support</Link></li> */}
                </ul >
            </nav >
        );
    }
}
;


const mapStateToProps = (state: any) => {
    return {
        loginStore: state.order.loginStore,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        storesList: () => {
            dispatch(storesList());
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);