import { LOGIN_USER, FORGOT_PASS, EDIT_PROFILE, GET_PROFILE, COUNTRY_LIST, ROLES_FEATURES } from '../actions/userType'
const initialState: any = {
    userData: {},
    countries: [] || "",
    features: [] || "",
    message: "",
    isUpdated: "",
    isLoggedIn: "",
    isReset: ""
}

const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN_USER: return {
            isLoggedIn: action.isLoggedIn,
            message: action.payload
        }
        case FORGOT_PASS: return {
            isReset: action.isReset,
            message: action.payload
        }
        case GET_PROFILE: return {
            ...state,
            userData: action.payload
        }
        case EDIT_PROFILE: return {
            ...state,
            message: action.payload,
            isUpdated: action.isUpdated
        }
        case COUNTRY_LIST: return {
            ...state,
            countries: action.payload
        }
        case ROLES_FEATURES: return {
            ...state,
            features: action.payload
        }
        default: return state;
    }
}
export default userReducer;