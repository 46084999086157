import { RECENT_PAYMENTS, RECENT_ORDERS, DOUGNUT_CHART_CHANNEL, PIE_CHART_SOS, LINE_CHART, RECENT_ITEMS, HOME_COUNTER, MONTHLY_ORDERS,MONTHLY_SALES,CUSTOMERS_LIST } from './homeType'
import Api from '../../components/Api';
import jwt_decode from "jwt-decode";
export const homeCounter = (days: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var store:any = jwt_decode(token);
        Api.post(`/store/home_counter/${store.store_id}`, days, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: HOME_COUNTER,
                        payload: response.data.successResponse[0]
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const LineChartData = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var store:any = jwt_decode(token);
        Api.post('/admin/last_seven_days_linechart', {store_id: store.store_id}, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                //console.log("response line", response);
                if (response.data.success) {
                    dispatch({
                        type: LINE_CHART,
                        payload: response.data.successResponse
                    })
                    //console.log("In action: ", response.data.successResponse[0]);
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const PieChartSOS = () => {
    return function (dispatch: any) {
    let token: any = sessionStorage.getItem('token');
    var store:any = jwt_decode(token);
        Api.post('/admin/last_seven_days_piechart_sos', {store_id: store.store_id}, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log("response pie chart", response);
                if (response.data.success) {
                    dispatch({
                        type: PIE_CHART_SOS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const DougnutChartChannel = () => {
    return function (dispatch: any) {
    let token: any = sessionStorage.getItem('token');
    var store:any = jwt_decode(token);
        Api.post('/admin/last_seven_days_dougnutchart_channel', {store_id: store.store_id}, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                console.log("response channel chart", response.data.successResponse[0]);
                if (response.data.success) {
                    dispatch({
                        type: DOUGNUT_CHART_CHANNEL,
                        payload: response.data.successResponse[0]
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const monthlyOrdersForLine = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/store/monthly_orders', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MONTHLY_ORDERS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const monthlySalesForBar = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/store/monthly_sales', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MONTHLY_SALES,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const recentOrders = (days: any)  => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var store:any = jwt_decode(token);
        Api.post(`/store/recent_orders/${store.store_id}`,days, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: RECENT_ORDERS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: RECENT_ORDERS,
                        payload: []
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const customersList = (days: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');  
        Api.post(`/store/top_customers`,days, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: CUSTOMERS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: CUSTOMERS_LIST,
                        payload: []
                    })
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
} 