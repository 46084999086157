import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logoutUser, storesList } from '../redux'
import './topbar.css'
class Topbar extends Component<{ logoutUser: () => {}, storesList: () => {}, loginStore: any }, {showTip: any}> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            showTip: false
        }
    }
    componentDidMount() {
        this.props.storesList()
    }
    render() {
        let storeName = sessionStorage.getItem('store_name');
        
        return (
            <header className="header">
                <nav className="navbar">
                    <div className="container-fluid">
                        <div className="navbar-holder d-flex align-items-center justify-content-between">
                            {/* <!-- Navbar Header--> */}
                            <div className="navbar-header">
                                {/* <!-- Navbar Brand --> */}
                                <Link to="/dashboard" className="navbar-brand d-sm-inline-block">
                                    {
                                        storeName ?
                                            <div className="brand-text brand-text-hover d-lg-inline-block"><span><strong>{storeName}</strong></span></div>
                                            :
                                            <div className="brand-text brand-text-hover d-lg-inline-block"><span>Simplex</span><strong>HDS</strong></div>
                                    }
                                </Link>
                                {/* <!-- Toggle Button--> */}
                                {/* <Link id="toggle-btn" to="#" className="menu-btn active"><span></span><span></span><span></span></Link> */}
                            </div>
                            {/* <!-- Navbar Menu --> */}
                            <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
                                <StatusesFormatter data={this.props.loginStore}/>
                                <li className="nav-item" title="Profile"><Link to="/profile" className="nav-link"><i style={{fontSize:"1.3rem"}} className="fa fa-user-circle"></i></Link></li>
                                {/* <!-- Logout    --> */}
                                <li onClick={() => this.props.logoutUser()} title="Logout" className="nav-item btn" style={{paddingLeft:"0"}}><i style={{fontSize:"1.3rem"}} className="nnnnn fa fa-sign-out"></i></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}
;
function HoursArray(hourDiff: any, starttime: any) {
    // console.log("HoursArray", hourDiff, starttime)
    if (String(hourDiff).indexOf('-') !== -1) {
        hourDiff = hourDiff + 24;
    }
    var hours: any = [];
    let hour = starttime;
    for (let i = 0; i <= hourDiff; i++) {
        hour = starttime + i;
        let AmOrPm = '';
        hour >= 12 && hour < 24 ? (AmOrPm = 'pm') : (AmOrPm = 'am');
        hour = hour % 12 || 12;
        hours.push(hour + AmOrPm);
    }
    var string = hours.join(':');
    return string;
};
class StatusesFormatter extends React.Component<{ data: any}, { [x: number]: any, showHide:any}> {
    constructor(props: any) {
        super(props);
        this.state = {
            showHide: false
        }
    }
    componentDidMount() {
    }
    render() {
        const { data } = this.props
        let today = new Date();
        //convert the current time 24 hour to 12 hour
        let CurrentHours: any = today.getHours();
        let CurrentAmOrPm = CurrentHours >= 12 ? 'pm' : 'am';
        CurrentHours = CurrentHours % 12 || 12;
        let Currenttime: any = CurrentHours + '' + CurrentAmOrPm;
        let response: any = "";
        let HourString = [];
        if (data.isDays == 0) {
            //define hours or minutes
            var timeStartMin = new Date(
                '01/01/2007 ' + data.store_open_time,
            ).getMinutes();
            var timeEndMin = new Date(
                '01/01/2007 ' + data.store_close_time,
            ).getMinutes();
            var timeStartHours = new Date(
                '01/01/2007 ' + data.store_open_time,
            ).getHours();
            var timeEndHours = new Date(
                '01/01/2007 ' + data.store_close_time,
            ).getHours();
    
            var hourDiff: any = timeEndHours - timeStartHours;
            HourString = HoursArray(hourDiff, timeStartHours);
            let splitHourArray = HourString.split(":");
            if (HourString.indexOf(Currenttime) !== -1) { //if current Hour with am/pm is exist in HourString
                if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
                    if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                        response = "false";
                        //if current Hour with am/pm is exist in HourString at last index
                    } else {
                        response = "true";
                    }
                } else {
                    if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
                        if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                            if (today.getMinutes() < timeEndMin) {
                                response = "true";
                            } else {
                                response = "false";
                            }
                        } else {
                            response = "true";
                        }
                    } else { //if store opening or closing minute is not 00
                        if (
                            today.getMinutes() >= timeStartMin ||
                            today.getMinutes() <= timeEndMin
                        ) {
                            response = "true";
                        } else {
                            response = "false";
                        }
                    }
                }
            } else {
                response = "false";
            }
        } else if (data.isDays == 1) {
            let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            let weekDaysTimingArr = JSON.parse(data.daysTiming)
            let todayDay = today.getDay();
            let dayName = days[todayDay];
            weekDaysTimingArr.map((storeDay: any) => {
                if (dayName == storeDay.day) {
                    var timeStartMin = new Date(
                        '01/01/2007 ' + storeDay.opening,
                    ).getMinutes();
                    var timeEndMin = new Date(
                        '01/01/2007 ' + storeDay.closing,
                    ).getMinutes();
                    var timeStartHours = new Date(
                        '01/01/2007 ' + storeDay.opening,
                    ).getHours();
                    var timeEndHours = new Date(
                        '01/01/2007 ' + storeDay.closing,
                    ).getHours();
                    var hourDiff: any = timeEndHours - timeStartHours;
                    HourString = HoursArray(hourDiff, timeStartHours);
                    let splitHourArray = HourString.split(":");
                    if (HourString.indexOf(Currenttime) !== -1) { //if current Hour with am/pm is exist in HourString
                        if (timeStartMin == 0 && timeEndMin == 0) {  //if store opening minutes and closing minutes are 00
                            if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                                response = "false"; //if current Hour with am/pm is exist in HourString at last index
                            } else {
                                response = "true";
                            }
                        } else {
                            if (timeStartMin == 0 || timeEndMin == 0) {  //if store opening or closing minute is 00
                                if (splitHourArray.length - 1 == splitHourArray.indexOf(Currenttime)) {
                                    if (today.getMinutes() < timeEndMin) {
                                        response = "true";
                                    } else {
                                        response = "false";
                                    }
                                } else {
                                    response = "true";
                                }
                            } else { //if store opening or closing minute is not 00
                                if (
                                    today.getMinutes() >= timeStartMin ||
                                    today.getMinutes() <= timeEndMin
                                ) {
                                    response = "true";
                                } else {
                                    response = "false";
                                }
                            }
                        }
                    } else {
                        response = "false";
                    }
                }
            })
        }
    
        return (
            <div>
                 {data.is_suspend == 1 ? 
                <div>
                    <li onClick={() => this.setState({showHide:!this.state.showHide})} style={{ borderRadius: 5, cursor:'pointer' }} className="nav-item bg-danger" >
                        <div className="nav-link">
                            <i className="fa fa-exclamation-circle p-2" aria-hidden="true"></i>
                            <span className="d-sm-inline-block">Branch Suspended</span>
                        </div>

                    </li>
                    {this.state.showHide &&
                    <div className="info-tip">
                        <span>Branch is suspended due to unresolved earlier orders</span>
                    </div>
                    }
                </div>
                :
                <li style={{ borderRadius: 5 }} {...(response == "true" && { className: "nav-item bg-success" }) || (response == "false" && { className: "nav-item bg-danger" })}>
                    <div className="nav-link">
                        {/* <span className="d-sm-inline-block">{response == "true" ? "Opened" : "Closed"}</span> */}
                    </div>
                </li>
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        loginStore: state.order.loginStore,
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        storesList: () => {
            dispatch(storesList());
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);