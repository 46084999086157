import { GET_MENU, STATUS_CHANGE_REASONS,SEARCH_MENU_ITEM } from './menuTypes'
import jwt_decode from "jwt-decode";
import Api from '../../components/Api';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

export const getAllMenuList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var store: any = jwt_decode(token);
        Api.get(`/store/getAllMenu/${store.store_id}?user_group_id=${store.user_group_id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("response.data.successResponse", response.data.successResponse)
                    let groupsData: any = [];
                    let itemsData: any = [];
                    let comboData: any = [];
                    response.data.successResponse.forEach((obj: any) => {
                        if (obj.group_id) {
                            groupsData.push(obj)
                        } else if (obj.menu_item_id) {
                            itemsData.push(obj)
                        } else if (obj.combo_id) {
                            comboData.push(obj)
                        }
                    })
                    dispatch(searchMenuItem("", itemsData));
                    dispatch({
                        type: GET_MENU,
                        groupsData: groupsData,
                        itemsData: itemsData,
                        combosData: comboData
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const updateItemStatus = (data: any, history: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var store: any = jwt_decode(token);
        data.user_id = store.user_id;
        data.store_id = store.store_id;
        Api.put(`/store/updateItemStatus/${store.store_id}?user_group_id=${store.user_group_id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info("Status changed successfully", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch(getAllMenuList())
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const statusChangeReasons = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/reasons/reasonByType', {
            type: 'menu'
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: STATUS_CHANGE_REASONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const searchMenuItem = (value: any, menuItemData: any) => {
    return function (dispatch: any) {
        const items = menuItemData.filter((item: any) => item.menu_item_id.item_name.toLowerCase().includes(value.toLowerCase()));
        dispatch({
            type: SEARCH_MENU_ITEM,
            searchItemData: value == "" ? menuItemData : items,
        })
    }
}